// src/components/Leaderboard.tsx

import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { 
  Coins, 
  Trophy, 
  Target, 
  Zap, 
  Crown, 
  Medal,
  RefreshCw,
  Users,
  AlertCircle
} from 'lucide-react';
import { getLeaderboards } from '../services/firebaseService';
import { cacheService } from '../services/cacheService';
import { displayEnhancer } from '../services/displayEnhancer';


interface LeaderboardEntry {
  address: string;
  value: number;
  displayValue: string;
  rank?: number;
}

interface LeaderboardData {
  totalDice: LeaderboardEntry[];
  totalTon: LeaderboardEntry[];
  totalStakedDice: LeaderboardEntry[];
  totalStakedDton: LeaderboardEntry[];
  premiumRolls: LeaderboardEntry[];
  regularRolls: LeaderboardEntry[];
  doubleSixes: LeaderboardEntry[];
  rollingEarnings: LeaderboardEntry[];
  networkSize: LeaderboardEntry[];
  tierLevel: LeaderboardEntry[];
}

interface CategoryConfig {
  key: keyof LeaderboardData;
  label: string;
  icon: React.ReactNode;
  format: (value: number) => string;
  color: string;
}

const CATEGORIES: CategoryConfig[] = [
  {
    key: 'totalStakedDice',
    label: 'Staked DICE',
    icon: <Coins className="w-5 h-5" />,
    format: (v) => v.toLocaleString(),
    color: 'from-purple-500 to-purple-700'
  },
  {
    key: 'totalStakedDton',
    label: 'Staked dTON',
    icon: <Trophy className="w-5 h-5" />,
    format: (v) => v.toFixed(2),
    color: 'from-blue-500 to-blue-700'
  },
  {
    key: 'premiumRolls',
    label: 'Premium Rolls',
    icon: <Crown className="w-5 h-5" />,
    format: (v) => v.toLocaleString(),
    color: 'from-yellow-500 to-yellow-700'
  },
  {
    key: 'regularRolls',
    label: 'Regular Rolls',
    icon: <Target className="w-5 h-5" />,
    format: (v) => v.toLocaleString(),
    color: 'from-green-500 to-green-700'
  },
  {
    key: 'doubleSixes',
    label: 'Double Sixes',
    icon: <Zap className="w-5 h-5" />,
    format: (v) => v.toLocaleString(),
    color: 'from-pink-500 to-pink-700'
  },
  {
    key: 'rollingEarnings',
    label: 'Rolling Earnings',
    icon: <RefreshCw className="w-5 h-5" />,
    format: (v) => v.toLocaleString(),
    color: 'from-indigo-500 to-indigo-700'
  },
  {
    key: 'networkSize',
    label: 'Network Size',
    icon: <Users className="w-5 h-5" />,
    format: (v) => v.toLocaleString(),
    color: 'from-orange-500 to-orange-700'
  },
  {
    key: 'tierLevel',
    label: 'Tier Level',
    icon: <Medal className="w-5 h-5" />,
    format: (v) => ['Bronze', 'Silver', 'Gold', 'Platinum', 'Diamond'][v],
    color: 'from-amber-500 to-amber-700'
  }
];

const CACHE_KEY = 'global_leaderboard';
const CACHE_DURATION = 5 * 60 * 1000; // 5 minutes
const EXTENDED_CACHE_DURATION = 30 * 60 * 1000; // 30 minutes for quota errors

const Leaderboard: React.FC = () => {
  const [selectedCategory, setSelectedCategory] = useState<CategoryConfig>(CATEGORIES[0]);
  const [leaderboardData, setLeaderboardData] = useState<LeaderboardData | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [lastUpdate, setLastUpdate] = useState<Date | null>(null);


useEffect(() => {
  const fetchLeaderboards = async () => {
    try {
      setLoading(true);
      
      // Check cache first
      const cached = cacheService.get<LeaderboardData>(CACHE_KEY, CACHE_DURATION);
      if (cached) {
        setLeaderboardData(cached);
        setLoading(false);
        setLastUpdate(new Date());
        return;
      }

      // Get real data and enhance with seed data
      const realData = await getLeaderboards();
      const enhancedData = displayEnhancer.enhanceLeaderboard(realData);
      
      setLeaderboardData(enhancedData);
      setError(null);
      setLastUpdate(new Date());
      
      // Cache the enhanced result
      cacheService.set(CACHE_KEY, enhancedData);

    } catch (error: any) {
      console.error('Failed to fetch leaderboards:', error);
      setError(error.message);
      
      // If quota exceeded, try to use cached data with extended duration
      if (error.message.includes('Quota exceeded')) {
        const cached = cacheService.get<LeaderboardData>(CACHE_KEY, EXTENDED_CACHE_DURATION);
        if (cached) {
          setLeaderboardData(cached);
          setError('Using cached leaderboard data (refresh limit reached)');
          setLastUpdate(new Date());
        }
      }
    } finally {
      setLoading(false);
    }
  };

  fetchLeaderboards();
  const interval = setInterval(fetchLeaderboards, CACHE_DURATION);
  return () => clearInterval(interval);
}, []);

  if (loading || !leaderboardData) {
    return (
      <div className="min-h-screen bg-gradient-to-br from-purple-900 via-blue-900 to-indigo-900 
                    text-white flex items-center justify-center">
        <motion.div
          animate={{ rotate: 360 }}
          transition={{ duration: 1, repeat: Infinity, ease: "linear" }}
        >
          <Trophy className="w-8 h-8" />
        </motion.div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-br from-purple-900 via-blue-900 to-indigo-900 text-white p-4">
      <div className="max-w-4xl mx-auto">
        <div className="flex justify-between items-center mb-8">
          <h1 className="text-4xl font-bold">Global Leaderboard</h1>
          {lastUpdate && (
            <div className="text-sm opacity-75 flex items-center gap-2">
              Last updated: {lastUpdate.toLocaleTimeString()}
              {error && (
                <div className="text-yellow-400 flex items-center gap-1">
                  <AlertCircle className="w-4 h-4" />
                  <span>Using cached data</span>
                </div>
              )}
            </div>
          )}
        </div>

        {/* Category Selection */}
        <div className="grid grid-cols-2 md:grid-cols-3 gap-2 mb-8">
          {CATEGORIES.map((category) => (
            <button
              key={category.key}
              onClick={() => setSelectedCategory(category)}
              className={`p-3 rounded-xl transition-all ${
                selectedCategory.key === category.key
                  ? `bg-gradient-to-r ${category.color}`
                  : 'bg-white/10 hover:bg-white/20'
              }`}
            >
              <div className="flex items-center justify-center gap-2">
                {category.icon}
                <span className="font-semibold">{category.label}</span>
              </div>
            </button>
          ))}
        </div>

        {/* Leaderboard Table */}
        <div className="bg-white/10 rounded-xl p-4 backdrop-blur-sm">
          <AnimatePresence mode="wait">
            <motion.div
              key={selectedCategory.key}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.2 }}
              className="space-y-2"
            >
              {leaderboardData[selectedCategory.key]?.map((entry, index) => (
                <div
                  key={entry.address}
                  className={`flex items-center gap-4 p-4 rounded-lg ${
                    index < 3 ? `bg-gradient-to-r ${selectedCategory.color}` : 'bg-white/5'
                  }`}
                >
                  {/* Rank */}
                  <div className="w-8 text-center font-bold">
                    {index === 0 && '🥇'}
                    {index === 1 && '🥈'}
                    {index === 2 && '🥉'}
                    {index > 2 && `#${index + 1}`}
                  </div>

                  {/* Address */}
                  <div className="flex-1 font-mono text-sm">
                    {entry.address.slice(0, 6)}...{entry.address.slice(-4)}
                  </div>

                  {/* Value */}
                  <div className="font-bold">
                    {selectedCategory.format(entry.value)}
                  </div>
                </div>
              ))}
            </motion.div>
          </AnimatePresence>
        </div>

        {!leaderboardData[selectedCategory.key]?.length && (
          <div className="text-center py-8 text-gray-400">
            No data available for this category
          </div>
        )}
      </div>
    </div>
  );
};

export default Leaderboard;