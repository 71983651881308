// src/components/ParticipationPage.tsx
import React, { useState } from 'react';
import { useTonWallet, useTonAddress, useTonConnectUI } from '@tonconnect/ui-react';
import { 
  RefreshCw, 
  Award, 
  Coins, 
  History, 
  Users, 
  TrendingUp,
} from 'lucide-react';
import StakingVault from './StakingVault';
import { useReferral } from '../context/ReferralProvider';

const TIER_NAMES = ['Bronze', 'Silver', 'Gold', 'Platinum', 'Diamond'];
const TIER_COLORS = [
  'from-orange-400',
  'from-gray-400',
  'from-yellow-400',
  'from-blue-400',
  'from-purple-400',
];

const ParticipationPage: React.FC = () => {
  const [tonConnectUI] = useTonConnectUI();
  const wallet = useTonWallet();
  const userFriendlyAddress = useTonAddress(true);
  
  // Replace state with useReferral hook
  const { userData, loading, error, refresh } = useReferral();
  
  const [selectedTab, setSelectedTab] = useState<'stats' | 'history' | 'network'>('stats');
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;

  const getCurrentTier = (): number => {
    if (!userData) return 0;
    const referrals = userData.networkStats?.directReferrals || 0;
    if (referrals >= 50) return 4;
    if (referrals >= 30) return 3;
    if (referrals >= 15) return 2;
    if (referrals >= 5) return 1;
    return 0;
  };

  const getNextTierRequirement = (): number | null => {
    const requirements = [5, 15, 30, 50];
    const currentReferrals = userData?.networkStats?.directReferrals || 0;
    for (const req of requirements) {
      if (currentReferrals < req) return req;
    }
    return null;
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-gradient-to-br from-purple-900 via-blue-900 to-indigo-900 text-white flex items-center justify-center">
        <RefreshCw className="w-8 h-8 animate-spin" />
      </div>
    );
  }

  const currentTier = getCurrentTier();
  const nextTierReq = getNextTierRequirement();

  return (
    <div className="min-h-screen bg-gradient-to-br from-purple-900 via-blue-900 to-indigo-900 text-white">
      <div className="container mx-auto px-4 py-8 space-y-6">
        {wallet ? (
          <>
            <div className={`bg-gradient-to-r ${TIER_COLORS[currentTier]} to-purple-900 p-6 rounded-xl shadow-xl`}>
              <div className="flex items-center justify-between mb-4">
                <div>
                  <h2 className="text-2xl font-bold flex items-center gap-2">
                    <Award className="w-6 h-6" />
                    {TIER_NAMES[currentTier]} Tier
                  </h2>
                  <p className="text-sm opacity-75">Current Level</p>
                </div>
                <div className="text-right">
                  <p className="text-sm">Rolls Available</p>
                  <p className="text-2xl font-bold">{userData?.rollsAvailable || 0}</p>
                </div>
              </div>
              {nextTierReq && (
                <div className="mt-4">
                  <p className="text-sm mb-1">Progress to {TIER_NAMES[currentTier + 1]}</p>
                  <div className="flex items-center gap-2">
                    <div className="flex-1 bg-black bg-opacity-25 rounded-full h-2">
                      <div
                        className="bg-white h-2 rounded-full transition-all"
                        style={{
                          width: `${((userData?.networkStats?.directReferrals || 0) / nextTierReq) * 100}%`
                        }}
                      />
                    </div>
                    <span className="text-sm whitespace-nowrap">
                      {nextTierReq - (userData?.networkStats?.directReferrals || 0)} more referrals
                    </span>
                  </div>
                </div>
              )}
            </div>

            <div className="flex rounded-lg bg-white bg-opacity-10 p-1 gap-1">
              <button
                onClick={() => setSelectedTab('stats')}
                className={`flex-1 py-2 rounded-md transition ${
                  selectedTab === 'stats' ? 'bg-purple-600' : 'hover:bg-white hover:bg-opacity-10'
                }`}
              >
                <Coins className="w-5 h-5 mx-auto" />
              </button>
              <button
                onClick={() => setSelectedTab('history')}
                className={`flex-1 py-2 rounded-md transition ${
                  selectedTab === 'history' ? 'bg-purple-600' : 'hover:bg-white hover:bg-opacity-10'
                }`}
              >
                <History className="w-5 h-5 mx-auto" />
              </button>
              <button
                onClick={() => setSelectedTab('network')}
                className={`flex-1 py-2 rounded-md transition ${
                  selectedTab === 'network' ? 'bg-purple-600' : 'hover:bg-white hover:bg-opacity-10'
                }`}
              >
                <Users className="w-5 h-5 mx-auto" />
              </button>
            </div>

            {selectedTab === 'stats' && userData && (
              <StakingVault 
                userWinnings={userData}
                userAddress={userFriendlyAddress}
                tonConnectUI={tonConnectUI}
                onStakingComplete={refresh} // Use refresh instead of refreshData
              />
            )}

            {selectedTab === 'history' && (
              <div className="space-y-4">
                <div className="bg-white bg-opacity-10 rounded-xl p-6">
                  <div className="flex justify-between items-center mb-4">
                    <h2 className="text-xl font-bold flex items-center gap-2">
                      <History className="w-5 h-5" />
                      Roll History
                    </h2>
                  </div>

                  <div className="space-y-3">
                    {[...(userData?.claimHistory || [])].sort((a, b) => b.timestamp - a.timestamp)
                      .slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)
                      .map((claim, index) => {
                        const totalRolls = userData?.claimHistory?.length || 0;
                        const rollNumber = totalRolls - ((currentPage - 1) * itemsPerPage + index);
                        
                        return (
                          <div key={index} className="bg-purple-900 bg-opacity-50 p-4 rounded-lg">
                            <div className="flex justify-between text-sm opacity-75 mb-2">
                              <div className="flex items-center gap-2">
                                <span>Roll #{rollNumber}</span>
                                <span className={`px-2 py-0.5 rounded-full text-xs ${
                                  claim.type === 'premium' 
                                    ? 'bg-pink-500/20 text-pink-300'
                                    : 'bg-green-500/20 text-green-300'
                                }`}>
                                  {claim.type === 'premium' ? 'Premium' : 'Regular'}
                                </span>
                              </div>
                              <span>{new Date(claim.timestamp).toLocaleString()}</span>
                            </div>
                            <div className="grid grid-cols-2 gap-2">
                              <div className="flex items-center gap-2">
                                <Coins className="w-4 h-4" />
                                <span>{claim.tonAmount.toFixed(2)} TON</span>
                              </div>
                              <div className="flex items-center gap-2">
                                <TrendingUp className="w-4 h-4" />
                                <span>{claim.diceAmount.toLocaleString()} $DICE</span>
                              </div>
                            </div>
                          </div>
                        );
                    })}
                  </div>

                  {(userData?.claimHistory?.length || 0) > 0 && (
                    <div className="flex justify-between items-center mt-6">
                      <div className="text-sm opacity-75">
                        Showing {((currentPage - 1) * itemsPerPage) + 1} to {
                          Math.min(currentPage * itemsPerPage, userData?.claimHistory?.length || 0)
                        } of {userData?.claimHistory?.length || 0} rolls
                      </div>
                      <div className="flex gap-2">
                        <button
                          onClick={() => setCurrentPage(prev => prev - 1)}
                          disabled={currentPage === 1}
                          className="px-3 py-1 rounded-lg bg-purple-900/50 hover:bg-purple-900/70 disabled:opacity-50 disabled:cursor-not-allowed text-sm"
                        >
                          Previous
                        </button>
                        <button
                          onClick={() => setCurrentPage(prev => prev + 1)}
                          disabled={currentPage >= Math.ceil(
                            (userData?.claimHistory?.length || 0) / itemsPerPage
                          )}
                          className="px-3 py-1 rounded-lg bg-purple-900/50 hover:bg-purple-900/70 disabled:opacity-50 disabled:cursor-not-allowed text-sm"
                        >
                          Next
                        </button>
                      </div>
                    </div>
                  )}

                  {!(userData?.claimHistory?.length) && (
                    <div className="text-center py-8 text-sm opacity-75">
                      No rolls yet
                    </div>
                  )}
                </div>
              </div>
            )}

            {selectedTab === 'network' && (
              <div className="space-y-4">
                <div className="bg-white bg-opacity-10 rounded-xl p-6">
                  <h2 className="text-xl font-bold mb-4 flex items-center gap-2">
                    <Users className="w-5 h-5" />
                    Referral Network
                  </h2>
                  <div className="grid grid-cols-2 gap-4 mb-6">
                    <div className="bg-purple-900 bg-opacity-50 p-4 rounded-lg">
                      <p className="text-sm opacity-75">Direct Referrals</p>
                      <p className="text-2xl font-bold">
                        {userData?.networkStats?.directReferrals || 0}
                      </p>
                    </div>
                    <div className="bg-purple-900 bg-opacity-50 p-4 rounded-lg">
                      <p className="text-sm opacity-75">Network Size</p>
                      <p className="text-2xl font-bold">
                        {userData?.networkStats?.totalNetworkSize || 0}
                      </p>
                    </div>
                  </div>
                  <div className="space-y-3">
                    {Object.entries(userData?.referrals || {}).map(([address, data]) => (
                      <div key={address} className="bg-purple-900 bg-opacity-50 p-4 rounded-lg">
                        <p className="text-sm font-mono mb-2 opacity-75">{address}</p>
                        <div className="grid grid-cols-2 gap-2 text-sm">
                          <div>Rolls: {data.totalRolls}</div>
                          <div>Earnings: {data.earnedTokens} $DICE</div>
                        </div>
                        <div className="text-sm opacity-75 mt-1">
                          Last Active: {data.lastRollAt ? new Date(data.lastRollAt).toLocaleString() : 'Never'}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}
          </>
        ) : (
          <div className="text-center text-xl bg-white bg-opacity-10 p-8 rounded-xl max-w-2xl mx-auto">
            Connect your wallet to view participation details.
          </div>
        )}

        {error && (
          <div className="bg-red-500 bg-opacity-20 p-4 rounded-xl text-center">
            {error}
          </div>
        )}
      </div>
    </div>
  );
};

export default ParticipationPage;