import React, { useState } from 'react';
import { Twitter, Gift, Medal, AlertCircle, ExternalLink } from 'lucide-react';
import { useReferral } from '../context/ReferralProvider';
import { useTonWallet, useTonAddress } from '@tonconnect/ui-react';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../firebase';

interface Mission {
  id: string;
  title: string;
  description: string;
  icon: React.ReactNode;
  reward: {
    premiumRolls: number;
    diceTokens: number;
  };
  link: string;
  buttonText: string;
}

const missions: Mission[] = [
  {
    id: 'twitter-follow',
    title: 'Follow on Twitter',
    description: 'Follow @TonDiceAirdrop on Twitter/X to unlock an extra premium roll!',
    icon: <Twitter className="w-6 h-6" />,
    reward: {
      premiumRolls: 1,
      diceTokens: 100
    },
    link: 'https://x.com/TonDiceAirdrop',
    buttonText: 'Follow & Verify'
  }
  // Add more missions here
];

export default function MissionsPage() {
  const { userData, refresh } = useReferral();
  const wallet = useTonWallet();
  const userAddress = useTonAddress(true);
  const [verifying, setVerifying] = useState<string | null>(null);

  const handleVerification = async (mission: Mission) => {
    if (!userAddress || !wallet) return;
    
    setVerifying(mission.id);
    try {
      // Open Twitter in new window
      window.open(mission.link, '_blank');

      // Get user document
      const userRef = doc(db, 'userWinnings', userAddress);
      const userDoc = await getDoc(userRef);
      
      if (!userDoc.exists()) {
        throw new Error('User not found');
      }

      const data = userDoc.data();
      const completedMissions = data.completedMissions || [];

      // Check if mission already completed
      if (completedMissions.includes(mission.id)) {
        throw new Error('Mission already completed');
      }

      // For now, we'll auto-verify after a delay
      // In production, this should be replaced with actual verification
      await new Promise(resolve => setTimeout(resolve, 5000));

      // Update user document
      await updateDoc(userRef, {
        completedMissions: [...completedMissions, mission.id],
        rollsAvailable: (data.rollsAvailable || 0) + mission.reward.premiumRolls,
        rollingEarnings: [
          ...(data.rollingEarnings || []),
          {
            amount: mission.reward.diceTokens,
            tokenType: 'DICE',
            timestamp: Date.now(),
            expiresAt: Date.now() + (7 * 24 * 60 * 60 * 1000), // 7 days expiry
            staked: false,
            rollId: crypto.randomUUID()
          }
        ],
        lastUpdated: Date.now()
      });

      // Refresh user data
      await refresh();

      // Show success message
      alert('Mission completed! Rewards claimed successfully.');

    } catch (error: any) {
      alert(error.message || 'Failed to verify mission');
    } finally {
      setVerifying(null);
    }
  };

  const isMissionComplete = (missionId: string) => {
    return userData?.completedMissions?.includes(missionId);
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-purple-900 via-blue-900 to-indigo-900 text-white">
      <div className="container mx-auto px-4 py-8">
        {/* Header */}
        <div className="text-center mb-12">
          <h1 className="text-4xl font-bold mb-4">
            <span className="text-yellow-400">Social</span>{' '}
            <span className="text-pink-400">Missions</span>
          </h1>
          <p className="text-xl text-purple-200 max-w-2xl mx-auto">
            Complete social media tasks to earn premium rolls and DICE tokens!
          </p>
        </div>

        {/* Mission Cards */}
        <div className="max-w-3xl mx-auto space-y-6">
          {missions.map((mission) => (
            <div
              key={mission.id}
              className="bg-white/10 backdrop-blur-lg rounded-xl p-6 border border-purple-500/30"
            >
              {/* Mission Header */}
              <div className="flex items-center gap-4 mb-4">
                <div className="bg-purple-500/20 p-3 rounded-lg">
                  {mission.icon}
                </div>
                <div>
                  <h3 className="text-xl font-bold">{mission.title}</h3>
                  <p className="text-purple-200">{mission.description}</p>
                </div>
              </div>

              {/* Rewards */}
              <div className="flex gap-4 mb-6">
                {mission.reward.premiumRolls > 0 && (
                  <div className="bg-purple-900/20 px-4 py-2 rounded-lg flex items-center gap-2">
                    <Gift className="w-4 h-4 text-pink-400" />
                    <span>{mission.reward.premiumRolls} Premium Roll</span>
                  </div>
                )}
                {mission.reward.diceTokens > 0 && (
                  <div className="bg-purple-900/20 px-4 py-2 rounded-lg flex items-center gap-2">
                    <Medal className="w-4 h-4 text-yellow-400" />
                    <span>{mission.reward.diceTokens} DICE</span>
                  </div>
                )}
              </div>

              {/* Action Button */}
              {!wallet ? (
                <div className="text-center p-3 bg-purple-900/20 rounded-lg">
                  Connect wallet to participate
                </div>
              ) : isMissionComplete(mission.id) ? (
                <div className="text-center p-3 bg-green-900/20 rounded-lg text-green-400 flex items-center justify-center gap-2">
                  <Medal className="w-5 h-5" />
                  Mission Completed!
                </div>
              ) : (
                <button
                  onClick={() => handleVerification(mission)}
                  disabled={verifying === mission.id}
                  className="w-full bg-gradient-to-r from-purple-500 to-pink-500 
                           hover:from-purple-600 hover:to-pink-600 py-3 px-6 rounded-lg
                           flex items-center justify-center gap-2 disabled:opacity-50"
                >
                  {verifying === mission.id ? (
                    'Verifying...'
                  ) : (
                    <>
                      <ExternalLink className="w-5 h-5" />
                      {mission.buttonText}
                    </>
                  )}
                </button>
              )}

              {/* Note */}
              <div className="mt-4 text-sm flex items-center gap-2 text-purple-200">
                <AlertCircle className="w-4 h-4" />
                <span>Make sure to keep the Twitter page open until verification is complete</span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
