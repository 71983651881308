import React, { useState, useEffect, useCallback } from 'react';
import { useTonConnectUI, useTonWallet, useTonAddress } from '@tonconnect/ui-react';
import { motion } from 'framer-motion';
import { RefreshCw } from 'lucide-react';
import Swal from 'sweetalert2';

import GameStage from './components/GameStage';
import RollButton from './components/RollButton';
import PrizeTable from './components/PrizeTable';
import WinOverlay from './components/WinOverlay';
import { GAME_CONFIG } from './config';
import { calculatePrize } from './utils/calculatePrize';
import { generateDiceValues, validateDiceValues } from './utils/diceValidation';
import { updateUserWinnings, decrementRollsAvailable } from '../../services/firebaseService';
import type { GameState } from '../../types';
import { useReferral } from '../../context/ReferralProvider';

const DiceGame: React.FC = () => {
  const [tonConnectUI] = useTonConnectUI();
  const wallet = useTonWallet();
  const userAddress = useTonAddress(true);
  const { userData, loading, error, refresh } = useReferral();
  const [regularCooldown, setRegularCooldown] = useState<string>('Ready!');
  const [availableRolls, setAvailableRolls] = useState<number>(0);
  const [gameState, setGameState] = useState<GameState>({
    isRolling: false,
    currentRoll: null,
    showWinOverlay: false
  });

  useEffect(() => {
    if (userData) {
      // Get mission-based rolls
      const missionRolls = userData.rollsAvailable || 0;
      
      // Calculate tier-based rolls
      const directReferrals = userData.networkStats?.directReferrals || 0;
      const totalFreeRolls = Math.floor(directReferrals / 5) + 1;
      const usedFreeRolls = userData.usedTierRolls?.length || 0;
      const tierRolls = Math.max(totalFreeRolls - usedFreeRolls, 0);
      
      // Combine both sources
      setAvailableRolls(missionRolls + tierRolls);
    }
  }, [userData]);

  useEffect(() => {
    const updateCooldowns = () => {
      if (!userData?.lastRegularRollTimestamp) {
        setRegularCooldown('Ready!');
        return;
      }

      const now = Date.now();
      const nextRegularRoll = userData.lastRegularRollTimestamp + GAME_CONFIG.ROLL_COOLDOWN.regular;
      const timeLeftRegular = nextRegularRoll - now;

      if (timeLeftRegular <= 0) {
        setRegularCooldown('Ready!');
      } else {
        const minutes = Math.floor((timeLeftRegular % (60 * 60 * 1000)) / (60 * 1000));
        setRegularCooldown(`${minutes}m`);
      }
    };

    updateCooldowns();
    const interval = setInterval(updateCooldowns, 60000);
    return () => clearInterval(interval);
  }, [userData]);
  
  const canRoll = useCallback((type: 'regular' | 'premium'): boolean => {
    if (gameState.isRolling || !userData) return false;
    
    if (type === 'regular') {
      if (!userData.lastRegularRollTimestamp) return true;
      return Date.now() - userData.lastRegularRollTimestamp >= GAME_CONFIG.ROLL_COOLDOWN.regular;
    }
    
    return true;
  }, [gameState.isRolling, userData]);

  const handleRoll = async (type: 'regular' | 'premium') => {
    if (!wallet) {
      Swal.fire('Connect Wallet', 'Please connect your wallet to play', 'warning');
      return;
    }

    if (gameState.isRolling) {
      Swal.fire('Roll in Progress', 'Please wait for the current roll to complete', 'warning');
      return;
    }

    if (!canRoll(type)) {
      if (type === 'regular') {
        Swal.fire('Cooldown Active', `Next roll available in: ${regularCooldown}`, 'info');
      }
      return;
    }

    if (type === 'premium') {
      if (availableRolls > 0) {
        if (!userAddress) {
          Swal.fire('Error', 'User address is unavailable.', 'error');
          return;
        }
        
        initiateRoll(type);
        try {
          await decrementRollsAvailable(userAddress, 1);
          await refresh();
        } catch (error: any) {
          console.error('Failed to decrement rollsAvailable:', error);
          Swal.fire('Error', 'Failed to update roll availability.', 'error');
        }
      } else {
        try {
          const confirmPayment = await Swal.fire({
            title: 'Premium Roll',
            text: 'A fee of 0.2 TON will be charged for this premium roll.',
            icon: 'info',
            showCancelButton: true,
            confirmButtonText: 'Pay and Roll',
          });

          if (!confirmPayment.isConfirmed) return;

          await tonConnectUI.sendTransaction({
            validUntil: Date.now() + 5 * 60 * 1000,
            messages: [{
              address: GAME_CONFIG.TREASURY_ADDRESS,
              amount: GAME_CONFIG.CLAIM_FEES.premium.toString()
            }]
          });
          
          initiateRoll(type);
        } catch (error: any) {
          console.error('Premium roll payment failed:', error);
          Swal.fire('Payment Failed', error.message || 'Failed to process payment.', 'error');
        }
      }
      return;
    }

    initiateRoll(type);
  };

  const initiateRoll = (type: 'regular' | 'premium') => {
    try {
      const diceValues = generateDiceValues();
      setGameState({
        isRolling: true,
        currentRoll: { type, values: diceValues },
        showWinOverlay: false
      });
    } catch (error) {
      console.error('Roll failed:', error);
      Swal.fire('Error', 'Failed to process roll', 'error');
      setGameState(prev => ({ ...prev, isRolling: false }));
    }
  };

  const handleRollEnd = async (displayed: { dice1: number; dice2: number }) => {
    const { currentRoll } = gameState;
    if (!currentRoll || !userAddress) return;

    if (!validateDiceValues(currentRoll.values, displayed)) {
      console.error('Dice validation failed');
      setGameState(prev => ({ ...prev, isRolling: false, currentRoll: null }));
      return;
    }

    const prize = calculatePrize(currentRoll.values, currentRoll.type);

    try {
      await updateUserWinnings(userAddress, {
        diceValues: currentRoll.values,
        type: currentRoll.type,
        tonAmount: prize.dton,
        diceAmount: prize.dice,
        timestamp: Date.now(),
        claimed: true,
        skipped: false
      });

      setGameState({
        isRolling: false,
        currentRoll: { ...currentRoll, prize },
        showWinOverlay: true
      });

      await refresh();

    } catch (error: any) {
      console.error('Roll update failed:', error);
      Swal.fire('Error', error.message || 'Failed to process roll', 'error');
      setGameState(prev => ({ ...prev, isRolling: false, currentRoll: null }));
    }
  };

  const handleWinClose = () => {
    setGameState({
      isRolling: false,
      currentRoll: null,
      showWinOverlay: false
    });
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-gradient-to-br from-purple-900 via-blue-900 to-indigo-900 text-white flex items-center justify-center">
        <motion.div
          animate={{ rotate: 360 }}
          transition={{ duration: 1, repeat: Infinity, ease: "linear" }}
        >
          <RefreshCw className="w-8 h-8" />
        </motion.div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen bg-gradient-to-br from-purple-900 via-blue-900 to-indigo-900 text-white flex items-center justify-center text-center p-4">
        <div>
          <p className="text-red-400 mb-4">{error}</p>
          <button 
            onClick={refresh}
            className="bg-purple-600 hover:bg-purple-700 px-4 py-2 rounded-lg"
          >
            Try Again
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-br from-purple-900 via-blue-900 to-indigo-900 text-white p-4">
      <div className="max-w-6xl mx-auto">
        <h1 className="text-4xl font-bold text-center mb-8">
          <span className="text-pink-400">ROLL</span> & <span className="text-yellow-300">WIN</span>
        </h1>
          
        <GameStage
          diceValues={gameState.currentRoll?.values || null}
          isRolling={gameState.isRolling}
          onRollComplete={handleRollEnd}
        />

        <div className="grid grid-cols-2 gap-4 max-w-xl mx-auto mt-8">
          <RollButton
            type="regular"
            isRolling={gameState.isRolling && gameState.currentRoll?.type === 'regular'}
            disabled={!canRoll('regular')}
            onClick={() => handleRoll('regular')}
            cooldown={regularCooldown}
          />
          <RollButton
            type="premium"
            isRolling={gameState.isRolling && gameState.currentRoll?.type === 'premium'}
            disabled={!canRoll('premium')}
            onClick={() => handleRoll('premium')}
            rollsAvailable={availableRolls}
          />
        </div>

        <PrizeTable />

        {gameState.currentRoll?.prize && (
          <WinOverlay
            show={gameState.showWinOverlay}
            prize={gameState.currentRoll.prize}
            diceValues={gameState.currentRoll.values}
            onClose={handleWinClose}
          />
        )}
      </div>
    </div>
  );
};

export default DiceGame;