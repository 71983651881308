// src/services/cacheService.ts

interface CacheItem<T> {
  data: T;
  timestamp: number;
}

class CacheService {
  private static instance: CacheService;
  private cache: Map<string, CacheItem<any>>;
  private readonly CACHE_DURATION = 30 * 1000; // 30 seconds
  private readonly LEADERBOARD_CACHE_DURATION = 5 * 60 * 1000; // 5 minutes

  private constructor() {
    this.cache = new Map();
  }

  static getInstance(): CacheService {
    if (!CacheService.instance) {
      CacheService.instance = new CacheService();
    }
    return CacheService.instance;
  }

  get<T>(key: string, cacheDuration?: number): T | null {
    const item = this.cache.get(key);
    if (!item) return null;

    const duration = cacheDuration || this.CACHE_DURATION;
    const isExpired = Date.now() - item.timestamp > duration;
    
    if (isExpired) {
      this.cache.delete(key);
      return null;
    }

    return item.data as T;
  }

  set<T>(key: string, data: T): void {
    this.cache.set(key, {
      data,
      timestamp: Date.now(),
    });
  }

  invalidate(key: string): void {
    this.cache.delete(key);
  }

  invalidatePattern(pattern: string): void {
    // Convert to array first to avoid iterator issues
    Array.from(this.cache.entries()).forEach(([key]) => {
      if (key.includes(pattern)) {
        this.cache.delete(key);
      }
    });
  }

  clear(): void {
    this.cache.clear();
  }
}

export const cacheService = CacheService.getInstance();