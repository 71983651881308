// src/components/DiceGame/components/WinOverlay.tsx

import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Sparkles } from 'lucide-react';
import type { Prize } from '../../../types';

interface WinOverlayProps {
  show: boolean;
  prize: Prize;
  diceValues: { dice1: number; dice2: number };
  onClose: () => void;
}

export default function WinOverlay({ 
  show, 
  prize, 
  diceValues,
  onClose
}: WinOverlayProps) {
  const total = diceValues.dice1 + diceValues.dice2;
  const isDouble = diceValues.dice1 === diceValues.dice2;
  const isDoubleSix = isDouble && diceValues.dice1 === 6;
  const isLucky = total === 7 || total === 11;

  return (
    <AnimatePresence>
      {show && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="fixed inset-0 flex items-center justify-center z-50 bg-black/50 backdrop-blur-sm"
        >
          <motion.div
            initial={{ scale: 0.8, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0.8, opacity: 0 }}
            className="bg-black/30 rounded-xl p-8 max-w-md w-full mx-4 backdrop-blur-md"
          >
            {/* Win Type */}
            <motion.div
              animate={{ 
                y: [0, -10, 0],
                transition: { repeat: Infinity, duration: 2 }
              }}
              className="text-center mb-6"
            >
              {isDoubleSix ? (
                <div className="text-yellow-400 text-3xl font-bold">
                  <Sparkles className="w-8 h-8 inline-block mb-2" />
                  <div>🎯 DOUBLE SIX! 🎯</div>
                </div>
              ) : isDouble ? (
                <div className="text-emerald-400 text-3xl font-bold">
                  <Sparkles className="w-8 h-8 inline-block mb-2" />
                  <div>🎲 DOUBLES! 🎲</div>
                </div>
              ) : isLucky ? (
                <div className="text-purple-400 text-3xl font-bold">
                  <Sparkles className="w-8 h-8 inline-block mb-2" />
                  <div>🍀 LUCKY NUMBER! 🍀</div>
                </div>
              ) : (
                <div className="text-blue-400 text-3xl font-bold">
                  Winner!
                </div>
              )}
            </motion.div>

            {/* Prize Amount */}
            <div className="text-center space-y-2 mb-6">
              {prize.dton > 0 && (
                <div className="text-2xl font-bold text-blue-400">
                  {prize.dton.toFixed(2)} dTON
                </div>
              )}
              {prize.dice > 0 && (
                <div className="text-2xl font-bold text-purple-400">
                  {prize.dice.toLocaleString()} DICE
                </div>
              )}
            </div>

            <div className="text-center text-gray-300 mb-6">
              Your winnings have been added to your vault and are ready to stake for the airdrop!
            </div>

            <button
              onClick={onClose}
              className="w-full bg-gradient-to-r from-green-500 to-emerald-500 
                       hover:from-green-600 hover:to-emerald-600
                       text-white font-bold py-4 px-6 rounded-xl
                       flex items-center justify-center gap-2"
            >
              <Sparkles className="w-5 h-5" />
              Awesome!
            </button>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}