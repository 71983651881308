// src/services/displayEnhancer.ts

import seedData from '../data/display-seed.json';

export interface LeaderboardEntry {
  address: string;
  value: number;
  displayValue: string;
  isSeed?: boolean;
}

export interface GlobalStakingStats {
  totalStakedDice: number;
  totalStakedDton: number;
  remainingRewards: number;
  totalStakers: number;
}

export interface LeaderboardData {
  totalDice: LeaderboardEntry[];
  totalTon: LeaderboardEntry[];
  totalStakedDice: LeaderboardEntry[];
  totalStakedDton: LeaderboardEntry[];
  premiumRolls: LeaderboardEntry[];
  regularRolls: LeaderboardEntry[];
  doubleSixes: LeaderboardEntry[];
  rollingEarnings: LeaderboardEntry[];
  networkSize: LeaderboardEntry[];
  tierLevel: LeaderboardEntry[];
}

const CATEGORIES = [
  'totalStakedDice',
  'totalStakedDton',
  'premiumRolls',
  'regularRolls',
  'doubleSixes',
  'rollingEarnings',
  'networkSize',
  'tierLevel'
] as const;

type LeaderboardCategory = typeof CATEGORIES[number];

export class DisplayEnhancer {
  enhanceLeaderboard(realData: Partial<LeaderboardData>): LeaderboardData {
    const enhanced: LeaderboardData = {
      // Keep real data for these categories
      totalDice: realData.totalDice || [],
      totalTon: realData.totalTon || [],
      totalStakedDice: [],
      totalStakedDton: [],
      premiumRolls: [],
      regularRolls: [],
      doubleSixes: [],
      rollingEarnings: [],
      networkSize: [],
      tierLevel: []
    };

    // Enhance each category with seed data
    CATEGORIES.forEach((category) => {
      const seedEntries = this.getSeedEntries(category);
      const realEntries = realData[category] || [];

      // Combine real and seed data, prioritizing real data
      enhanced[category] = [...realEntries, ...seedEntries]
        .sort((a, b) => b.value - a.value)
        .slice(0, 10)
        .map(entry => ({
          ...entry,
          displayValue: this.formatValue(entry.value, category)
        }));
    });

    return enhanced;
  }

  enhanceGlobalStats(realStats: Partial<GlobalStakingStats>): GlobalStakingStats {
    const realStakedDice = realStats.totalStakedDice || 0;
    const realStakedDton = realStats.totalStakedDton || 0;
    const realStakers = realStats.totalStakers || 0;

    // Only add seed data if real data is below certain thresholds
    const shouldAddSeedData = realStakedDice < 50000000; // Example threshold

    return {
      totalStakedDice: realStakedDice + (shouldAddSeedData ? seedData.enhancement.totalStakedDice : 0),
      totalStakedDton: realStakedDton + (shouldAddSeedData ? seedData.enhancement.totalStakedDton : 0),
      remainingRewards: Math.max(
        0,
        seedData.enhancement.remainingRewards - realStakedDice
      ),
      totalStakers: realStakers + (shouldAddSeedData ? seedData.enhancement.categories.totalStakedDice.length : 0)
    };
  }

  private getSeedEntries(category: LeaderboardCategory): LeaderboardEntry[] {
    const entries = seedData.enhancement.categories[category];
    
    return entries.map(entry => {
      const value = this.extractValue(entry, category);
      return {
        address: entry.address,
        value,
        displayValue: this.formatValue(value, category),
        isSeed: true
      };
    });
  }

  private extractValue(entry: any, category: LeaderboardCategory): number {
    switch (category) {
      case 'totalStakedDice':
        return entry.staking?.dice || 0;
      case 'totalStakedDton':
        return entry.staking?.dton || 0;
      case 'premiumRolls':
        return entry.gameStats?.premiumRolls || 0;
      case 'regularRolls':
        return entry.gameStats?.regularRolls || 0;
      case 'doubleSixes':
        return entry.gameStats?.doubleSixes || 0;
      case 'rollingEarnings':
        return entry.rollingEarnings || 0;
      case 'networkSize':
        return entry.networkStats?.totalNetworkSize || 0;
      case 'tierLevel':
        return entry.currentTier || 0;
      default:
        return 0;
    }
  }

  private formatValue(value: number, category: LeaderboardCategory): string {
    if (category === 'tierLevel') {
      const TIER_NAMES = ['Bronze', 'Silver', 'Gold', 'Platinum', 'Diamond'];
      return TIER_NAMES[value] || 'Bronze';
    }
    return value.toLocaleString();
  }
}

export const displayEnhancer = new DisplayEnhancer();