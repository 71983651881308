import React from 'react';
import { 
  Zap, 
  Gift, 
  Trophy,
  Coins,
  Lock,
  Timer,
  Star,
  Percent
} from 'lucide-react';

const DiceNomicsPage: React.FC = () => {
  return (
    <div className="min-h-screen bg-gradient-to-br from-purple-900 via-blue-900 to-indigo-900 text-white">
      {/* Hero Section */}
      <div className="container mx-auto px-4 py-16">
        <div className="text-center mb-16">
          <h1 className="text-6xl font-bold mb-4 font-cherry bg-clip-text text-transparent bg-gradient-to-r from-yellow-400 to-pink-500">
            $DICE Tokenomics
          </h1>
          <p className="font-['Space_Grotesk'] text-2xl text-purple-200 max-w-2xl mx-auto">
            A fair, sustainable and fun token generation event powering the future of decentralized gaming on TON
          </p>
        </div>

        {/* Main Token Info */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 mb-16">
          <div className="bg-white/10 backdrop-blur-lg p-6 rounded-xl border border-purple-500/30 hover:border-purple-500/50 transition-colors">
            <div className="flex items-center gap-3 mb-4">
              <Coins className="text-yellow-400" size={32} />
              <h3 className="text-xl font-bold">Total Supply</h3>
            </div>
            <p className="text-3xl font-bold text-yellow-400 mb-2">1,000,000,000</p>
            <p className="text-purple-200">$DICE tokens</p>
          </div>

          <div className="bg-white/10 backdrop-blur-lg p-6 rounded-xl border border-purple-500/30 hover:border-purple-500/50 transition-colors">
            <div className="flex items-center gap-3 mb-4">
              <Gift className="text-pink-400" size={32} />
              <h3 className="text-xl font-bold">Airdrop</h3>
            </div>
            <p className="text-3xl font-bold text-pink-400 mb-2">500,000,000</p>
            <p className="text-purple-200">Community distribution</p>
          </div>

          <div className="bg-white/10 backdrop-blur-lg p-6 rounded-xl border border-purple-500/30 hover:border-purple-500/50 transition-colors">
            <div className="flex items-center gap-3 mb-4">
              <Lock className="text-green-400" size={32} />
              <h3 className="text-xl font-bold">Staking APY</h3>
            </div>
            <p className="text-3xl font-bold text-green-400 mb-2">Up to 4000%</p>
            <p className="text-purple-200">Initial staking APY</p>
          </div>

          <div className="bg-white/10 backdrop-blur-lg p-6 rounded-xl border border-purple-500/30 hover:border-purple-500/50 transition-colors">
            <div className="flex items-center gap-3 mb-4">
              <Star className="text-blue-400" size={32} />
              <h3 className="text-xl font-bold">Staking Pool</h3>
            </div>
            <p className="text-3xl font-bold text-blue-400 mb-2">200M DICE</p>
            <p className="text-purple-200">Total rewards</p>
          </div>
        </div>

        {/* How to Qualify */}
        <div className="mb-16">
          <h2 className="text-3xl font-bold mb-8 text-center">How to Qualify for 500M Airdrop</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <div className="bg-white/10 backdrop-blur-lg p-8 rounded-xl border border-purple-500/30 hover:border-purple-500/50 transition-colors">
              <h3 className="text-2xl font-bold mb-4 text-yellow-400">Roll & Earn</h3>
              <ul className="space-y-4">
                <li className="flex items-start gap-3">
                  <div className="mt-1 p-1 bg-yellow-400 rounded-full">
                    <Zap className="w-4 h-4 text-purple-900" />
                  </div>
                  <div>
                    <p className="font-bold mb-1">Daily Rolls</p>
                    <p className="text-purple-200">Earn DICE & dTON tokens with each roll</p>
                  </div>
                </li>
                <li className="flex items-start gap-3">
                  <div className="mt-1 p-1 bg-yellow-400 rounded-full">
                    <Lock className="w-4 h-4 text-purple-900" />
                  </div>
                  <div>
                    <p className="font-bold mb-1">Vault Your Earnings</p>
                    <p className="text-purple-200">Stake your earnings to secure airdrop eligibility</p>
                  </div>
                </li>
                <li className="flex items-start gap-3">
                  <div className="mt-1 p-1 bg-yellow-400 rounded-full">
                    <Timer className="w-4 h-4 text-purple-900" />
                  </div>
                  <div>
                    <p className="font-bold mb-1">Early Staker Bonus</p>
                    <p className="text-purple-200">First 1,000 stakers get 2x APY boost</p>
                  </div>
                </li>
              </ul>
            </div>

            <div className="bg-white/10 backdrop-blur-lg p-8 rounded-xl border border-purple-500/30 hover:border-purple-500/50 transition-colors">
              <h3 className="text-2xl font-bold mb-4 text-green-400">Level Up Benefits</h3>
              <div className="space-y-4">
                <div className="p-4 bg-white/5 rounded-lg hover:bg-white/10 transition-colors">
                  <div className="flex justify-between items-center mb-2">
                    <span className="font-bold text-yellow-400">Diamond (50+ refs)</span>
                    <span>100% Boost</span>
                  </div>
                  <p className="text-sm text-purple-200">2.0x multiplier + 5 daily rolls</p>
                </div>
                <div className="p-4 bg-white/5 rounded-lg hover:bg-white/10 transition-colors">
                  <div className="flex justify-between items-center mb-2">
                    <span className="font-bold text-blue-400">Gold (15+ refs)</span>
                    <span>50% Boost</span>
                  </div>
                  <p className="text-sm text-purple-200">1.5x multiplier + 3 daily rolls</p>
                </div>
                <div className="p-4 bg-white/5 rounded-lg hover:bg-white/10 transition-colors">
                  <div className="flex justify-between items-center mb-2">
                    <span className="font-bold text-purple-400">Silver (5+ refs)</span>
                    <span>25% Boost</span>
                  </div>
                  <p className="text-sm text-purple-200">1.25x multiplier + 2 daily rolls</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Dynamic APY System */}
        <div className="mb-16">
          <h2 className="text-3xl font-bold mb-8 text-center">Dynamic APY System</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div className="bg-white/10 backdrop-blur-lg p-6 rounded-xl border border-purple-500/30 hover:border-purple-500/50 transition-colors">
              <div className="text-center mb-6">
                <div className="bg-purple-500/30 p-3 rounded-full w-16 h-16 flex items-center justify-center mx-auto mb-4">
                  <Percent className="w-8 h-8 text-purple-300" />
                </div>
                <h3 className="text-xl font-bold mb-2">Base APY</h3>
                <div className="space-y-2 text-left">
                  <div className="flex justify-between">
                    <span>First 1M</span>
                    <span className="text-green-400">1000%</span>
                  </div>
                  <div className="flex justify-between">
                    <span>Up to 5M</span>
                    <span className="text-green-400">500%</span>
                  </div>
                  <div className="flex justify-between">
                    <span>Up to 20M</span>
                    <span className="text-green-400">250%</span>
                  </div>
                </div>
              </div>
            </div>

            <div className="bg-white/10 backdrop-blur-lg p-6 rounded-xl border border-purple-500/30 hover:border-purple-500/50 transition-colors">
              <div className="text-center mb-6">
                <div className="bg-purple-500/30 p-3 rounded-full w-16 h-16 flex items-center justify-center mx-auto mb-4">
                  <Star className="w-8 h-8 text-purple-300" />
                </div>
                <h3 className="text-xl font-bold mb-2">Early Bonus</h3>
                <div className="space-y-2 text-left">
                  <div className="flex justify-between">
                    <span>First 1,000</span>
                    <span className="text-blue-400">2.0x</span>
                  </div>
                  <div className="flex justify-between">
                    <span>First 5,000</span>
                    <span className="text-blue-400">1.5x</span>
                  </div>
                  <div className="flex justify-between">
                    <span>First 10,000</span>
                    <span className="text-blue-400">1.25x</span>
                  </div>
                </div>
              </div>
            </div>

            <div className="bg-white/10 backdrop-blur-lg p-6 rounded-xl border border-purple-500/30 hover:border-purple-500/50 transition-colors">
              <div className="text-center mb-6">
                <div className="bg-purple-500/30 p-3 rounded-full w-16 h-16 flex items-center justify-center mx-auto mb-4">
                  <Trophy className="w-8 h-8 text-purple-300" />
                </div>
                <h3 className="text-xl font-bold mb-2">Tier Boost</h3>
                <div className="space-y-2 text-left">
                  <div className="flex justify-between">
                    <span>Diamond</span>
                    <span className="text-yellow-400">2.0x</span>
                  </div>
                  <div className="flex justify-between">
                    <span>Gold</span>
                    <span className="text-yellow-400">1.5x</span>
                  </div>
                  <div className="flex justify-between">
                    <span>Silver</span>
                    <span className="text-yellow-400">1.25x</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Roadmap */}
        <div>
          <h2 className="text-3xl font-bold mb-8 text-center">Development Roadmap</h2>
          <div className="max-w-3xl mx-auto space-y-8">
            <div className="relative pl-8 pb-8 border-l-2 border-purple-500">
              <div className="absolute -left-2 top-0 w-6 h-6 bg-purple-500 rounded-full" />
              <h3 className="text-xl font-bold mb-2 text-yellow-400">Phase 1 - Launch</h3>
              <p className="text-purple-200">Roll-to-earn mechanics, referral system activation, vault staking</p>
            </div>
            <div className="relative pl-8 pb-8 border-l-2 border-purple-500">
              <div className="absolute -left-2 top-0 w-6 h-6 bg-purple-500 rounded-full" />
              <h3 className="text-xl font-bold mb-2 text-yellow-400">Phase 2 - 500M Airdrop</h3>
              <p className="text-purple-200">Massive token distribution to early stakers, advanced games & tournaments</p>
            </div>
            <div className="relative pl-8">
              <div className="absolute -left-2 top-0 w-6 h-6 bg-purple-500 rounded-full" />
              <h3 className="text-xl font-bold mb-2 text-yellow-400">Phase 3 - Expansion</h3>
              <p className="text-purple-200">DAO governance, ecosystem partnerships, advanced gaming features</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DiceNomicsPage;