// src/components/Header.tsx
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { TonConnectButton } from '@tonconnect/ui-react';
import { Book, Dices, Coins, Users, Trophy, Medal } from 'lucide-react';
import { motion } from 'framer-motion';

const DiceLogo = () => (
  <div className="flex items-center gap-2">
    <div className="flex gap-1">
      <motion.div
        initial={{ rotate: 0 }}
        animate={{ rotate: 360 }}
        transition={{ duration: 2, repeat: Infinity, repeatType: "reverse", ease: "easeInOut" }}
        className="relative w-8 h-8"
      >
        <svg viewBox="0 0 100 100" className="absolute inset-0 w-full h-full">
          <rect width="100" height="100" rx="20" fill="#FFD700" />
          <circle cx="50" cy="50" r="10" fill="#1a1a1a" />
        </svg>
      </motion.div>
      <motion.div
        initial={{ rotate: 0 }}
        animate={{ rotate: -360 }}
        transition={{ duration: 2, repeat: Infinity, repeatType: "reverse", ease: "easeInOut", delay: 0.5 }}
        className="relative w-8 h-8"
      >
        <svg viewBox="0 0 100 100" className="absolute inset-0 w-full h-full">
          <rect width="100" height="100" rx="20" fill="#FF69B4" />
          <circle cx="30" cy="30" r="8" fill="#1a1a1a" />
          <circle cx="70" cy="70" r="8" fill="#1a1a1a" />
        </svg>
      </motion.div>
    </div>
    <div className="flex flex-col leading-tight">
      <motion.span 
        className="font-['Cherry_Bomb_One'] text-xl font-bold text-yellow-300"
        animate={{ scale: [1, 1.05, 1] }}
        transition={{ duration: 2, repeat: Infinity, ease: "easeInOut" }}
      >
        DICE
      </motion.span>
      <motion.span 
        className="font-['Cherry_Bomb_One'] text-xl font-bold text-pink-400"
        animate={{ scale: [1.05, 1, 1.05] }}
        transition={{ duration: 2, repeat: Infinity, ease: "easeInOut" }}
      >
        DROP
      </motion.span>
    </div>
  </div>
);

const Header: React.FC = () => {
  const location = useLocation();
  const isActive = (path: string) => location.pathname === path;

  const navItems = [
    {
      path: '/dicenomics',
      label: 'Dicenomics',
      icon: <Book className="w-5 h-5" />,
      bgColor: 'bg-purple-600'
    },
    {
      path: '/vault',
      label: 'Vault',
      icon: <Coins className="w-5 h-5" />,
      bgColor: 'bg-green-600'
    },
    {
      path: '/play',
      label: 'Play',
      icon: <Dices className="w-5 h-5" />,
      bgColor: 'bg-pink-600'
    },
    {
      path: '/referral',
      label: 'Referral',
      icon: <Users className="w-5 h-5" />,
      bgColor: 'bg-yellow-600'
    },
    {
      path: '/missions',
      label: 'Missions',
      icon: <Medal className="w-5 h-5" />,
      bgColor: 'bg-purple-600'
    },
    {
      path: '/leaderboard',
      label: 'Leaders',
      icon: <Trophy className="w-5 h-5" />,
      bgColor: 'bg-orange-600'
    }
  ];

  return (
    <>
      {/* Top Bar */}
      <div className="fixed top-0 left-0 right-0 bg-indigo-900/95 backdrop-blur-md border-b border-white/10 z-50 safe-top h-16">
        <div className="flex items-center justify-between px-4 h-full">
          <Link to="/">
            <DiceLogo />
          </Link>
          <TonConnectButton />
        </div>
      </div>

      {/* Bottom Navigation */}
      <nav className="fixed bottom-0 left-0 right-0 bg-indigo-900/95 backdrop-blur-md border-t border-white/10 z-50 safe-bottom">
        <div className="flex items-center justify-around px-2 py-2">
          {navItems.map((item) => (
            <Link
              key={item.path}
              to={item.path}
              className={`flex flex-col items-center p-2 rounded-xl transition-all duration-200
                ${isActive(item.path) 
                  ? `${item.bgColor} text-white scale-110` 
                  : 'text-gray-400 hover:text-white hover:bg-white/10'
                }
              `}
            >
              {item.icon}
              <span className="text-xs mt-1 font-medium">{item.label}</span>
            </Link>
          ))}
        </div>

        {/* iPhone Safe Area Spacer */}
        <div className="h-[env(safe-area-inset-bottom)]" />
      </nav>
    </>
  );
};

export default Header;