// src/components/DiceGame/components/GameStage.tsx

import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import type { GameStageProps } from '../../../types';

const DiceFace: React.FC<{ value: number; className?: string }> = ({ value, className = '' }) => {
  const dots = {
    1: [{ x: 50, y: 50 }],
    2: [{ x: 30, y: 30 }, { x: 70, y: 70 }],
    3: [{ x: 30, y: 30 }, { x: 50, y: 50 }, { x: 70, y: 70 }],
    4: [{ x: 30, y: 30 }, { x: 70, y: 30 }, { x: 30, y: 70 }, { x: 70, y: 70 }],
    5: [{ x: 30, y: 30 }, { x: 70, y: 30 }, { x: 50, y: 50 }, { x: 30, y: 70 }, { x: 70, y: 70 }],
    6: [{ x: 30, y: 25 }, { x: 30, y: 50 }, { x: 30, y: 75 }, { x: 70, y: 25 }, { x: 70, y: 50 }, { x: 70, y: 75 }]
  };

  return (
    <svg viewBox="0 0 100 100" className={`w-full h-full ${className}`}>
      <rect 
        width="100" 
        height="100" 
        rx="15" 
        fill="white" 
        stroke="#e2e8f0"
        strokeWidth="2"
      />
      {dots[value as keyof typeof dots].map((dot, i) => (
        <circle
          key={i}
          cx={dot.x}
          cy={dot.y}
          r="6"
          fill="#1a1a1a"
        />
      ))}
    </svg>
  );
};

const GameStage: React.FC<GameStageProps> = ({ diceValues, isRolling, onRollComplete }) => {
  useEffect(() => {
    if (isRolling && diceValues) {
      const timer = setTimeout(() => {
        onRollComplete({
          dice1: diceValues.dice1,
          dice2: diceValues.dice2
        });
      }, 1500);
      
      return () => clearTimeout(timer);
    }
  }, [isRolling, diceValues, onRollComplete]);

  return (
    <div className="w-full max-w-xl mx-auto aspect-video flex items-center justify-center">
      <div className="grid grid-cols-2 gap-8 p-8">
        {[1, 2].map((diceIndex) => (
          <motion.div
            key={diceIndex}
            animate={{
              rotate: isRolling ? [0, 360, 720, 1080] : 0,
              scale: isRolling ? [1, 1.1, 1] : 1
            }}
            transition={{
              duration: isRolling ? 1.5 : 0,
              ease: "easeInOut"
            }}
            className="w-32 h-32 relative"
          >
            <div className="w-full h-full rounded-xl shadow-lg overflow-hidden">
              <DiceFace 
                value={diceValues ? 
                  diceIndex === 1 ? diceValues.dice1 : diceValues.dice2 
                  : 1
                } 
              />
            </div>
          </motion.div>
        ))}
      </div>
    </div>
  );
};

export default GameStage;