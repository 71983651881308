// src/config/staking.config.ts

// Define the reduction threshold type
type ReductionThreshold = {
  readonly amount: number;
  readonly apy: number;
};

// Define the configuration object type
interface StakingConfig {
  readonly TOTAL_STAKING_ALLOCATION: number;
  readonly INITIAL_USER_THRESHOLD: number;
  readonly DTON_APY: {
    readonly BRONZE: number;
    readonly SILVER: number;
    readonly GOLD: number;
    readonly PLATINUM: number;
    readonly DIAMOND: number;
  };
  readonly TIER_MULTIPLIERS: {
    readonly BRONZE: number;
    readonly SILVER: number;
    readonly GOLD: number;
    readonly PLATINUM: number;
    readonly DIAMOND: number;
  };
  readonly DICE_APY: {
    readonly MAX_APY: number;
    readonly MIN_APY: number;
    readonly REDUCTION_THRESHOLDS: readonly ReductionThreshold[];
  };
  readonly EARLY_STAKER_BONUS: {
    readonly FIRST_100_USERS: number;
    readonly FIRST_500_USERS: number;
    readonly FIRST_1000_USERS: number;
  };
}

export const STAKING_CONFIG: StakingConfig = {
    TOTAL_STAKING_ALLOCATION: 200_000_000,
    INITIAL_USER_THRESHOLD: 1000,
    DTON_APY: {
      BRONZE: 5,
      SILVER: 7.5,
      GOLD: 10,
      PLATINUM: 12.5,
      DIAMOND: 15
    },
    TIER_MULTIPLIERS: {
      BRONZE: 1.0,
      SILVER: 1.25,
      GOLD: 1.5,
      PLATINUM: 1.75,
      DIAMOND: 2.0
    },
    DICE_APY: {
      MAX_APY: 1000,
      MIN_APY: 25,
      REDUCTION_THRESHOLDS: [
        { amount: 1_000_000, apy: 1000 },
        { amount: 5_000_000, apy: 500 },
        { amount: 20_000_000, apy: 250 },
        { amount: 50_000_000, apy: 100 },
        { amount: 100_000_000, apy: 50 },
        { amount: 200_000_000, apy: 25 }
      ]
    },
    EARLY_STAKER_BONUS: {
      FIRST_100_USERS: 2.0,
      FIRST_500_USERS: 1.5,
      FIRST_1000_USERS: 1.25
    }
} as const;