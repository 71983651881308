// src/components/DiceGame/utils/diceValidation.ts

import type { DiceValues } from '../../../types';

export const generateDiceValues = (): DiceValues => ({
  dice1: Math.floor(Math.random() * 6) + 1,
  dice2: Math.floor(Math.random() * 6) + 1,
  timestamp: Date.now()
});

export const validateDiceValues = (
  stored: DiceValues,
  displayed: { dice1: number; dice2: number }
): boolean => {
  // Ensure displayed values match stored values
  return stored.dice1 === displayed.dice1 && 
         stored.dice2 === displayed.dice2;
};

export const isValidDiceValue = (value: number): boolean => {
  return Number.isInteger(value) && value >= 1 && value <= 6;
};