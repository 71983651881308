// src/components/DiceGame/components/RollButton.tsx
import React from 'react';
import { motion } from 'framer-motion';
import { Timer, Gift, RefreshCw } from 'lucide-react';
import type { RollButtonProps } from '../../../types';

export default function RollButton({
  type,
  isRolling,
  disabled,
  onClick,
  cooldown,
  rollsAvailable
}: RollButtonProps) {
  const isPremium = type === 'premium';

  const renderRollsAvailable = () => {
    if (isPremium) {
      const freeRolls = rollsAvailable ?? 0;
      if (freeRolls > 0) {
        return `${freeRolls} free roll${freeRolls !== 1 ? 's' : ''} available`;
      }
      return 'No free rolls available';
    }
    return cooldown === 'Ready!' ? 'Ready to roll' : `Next roll in: ${cooldown}`;
  };

  return (
    <motion.button
      whileHover={{ scale: disabled ? 1 : 1.02 }}
      whileTap={{ scale: disabled ? 1 : 0.98 }}
      onClick={onClick}
      disabled={disabled || isRolling}
      className="relative w-full"
    >
      <div className="absolute inset-0 bg-gradient-to-br from-purple-500 to-pink-600 rounded-xl 
                   blur opacity-75 transition-opacity duration-300" />
      <div className={`
          relative w-full p-4 rounded-xl font-bold text-center
          ${isPremium ? 'bg-gradient-to-br from-purple-500 to-pink-600' 
                     : 'bg-gradient-to-br from-green-500 to-emerald-600'}
          ${disabled ? 'opacity-50 cursor-not-allowed' : 'opacity-100'}
          transition duration-200
        `}>
        {isRolling ? (
          <div className="flex items-center justify-center gap-2">
            <RefreshCw className="w-5 h-5 animate-spin" />
            <span>Rolling...</span>
          </div>
        ) : (
          <>
            <div className="flex items-center justify-center gap-2">
              {isPremium ? (
                <Gift className="w-5 h-5" />
              ) : (
                <Timer className="w-5 h-5" />
              )}
              <span>{isPremium ? 'Premium' : 'Regular'}</span>
            </div>
            <div className="text-sm mt-1 opacity-80">
              {renderRollsAvailable()}
            </div>
            <div className="text-xs mt-1">
              {isPremium && (rollsAvailable ?? 0) === 0 ? '0.2 TON' : 'Free!'}
            </div>
          </>
        )}
      </div>
    </motion.button>
  );
}