// src/components/DiceGame/utils/calculatePrize.ts

import { GAME_CONFIG } from '../config';
import type { DiceValues } from '../../../types';

interface Prize {
  dton: number;
  dice: number;
}

export const calculatePrize = (
  diceValues: DiceValues, 
  type: 'regular' | 'premium'
): Prize => {
  const { dice1, dice2 } = diceValues;
  const sum = dice1 + dice2;
  const isDouble = dice1 === dice2;
  const isDoubleSix = isDouble && dice1 === 6;
  const isLucky = sum === 7 || sum === 11;

  // Console log for debugging
  console.log('Roll Details:', {
    type,
    dice1,
    dice2,
    sum,
    isDouble,
    isDoubleSix,
    isLucky
  });

  if (type === 'regular') {
    if (isDoubleSix) {
      return GAME_CONFIG.PRIZES.regular.doubleSix;
    }
    if (isDouble) {
      return GAME_CONFIG.PRIZES.regular.double;
    }
    if (isLucky) {
      return GAME_CONFIG.PRIZES.regular.lucky;
    }
    // Base prize
    return { 
      dton: 0,
      dice: sum * GAME_CONFIG.REGULAR_MULTIPLIER 
    };
  }

  // Premium roll
  if (isDoubleSix) {
    return GAME_CONFIG.PRIZES.premium.doubleSix;
  }
  if (isDouble) {
    return GAME_CONFIG.PRIZES.premium.double;
  }
  if (isLucky) {
    return GAME_CONFIG.PRIZES.premium.lucky;
  }
  // Base prize for premium
  return { 
    dton: GAME_CONFIG.PRIZES.premium.base.dton,
    dice: sum * GAME_CONFIG.PREMIUM_MULTIPLIER 
  };
};