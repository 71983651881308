// src/context/ReferralProvider.tsx
import React, { createContext, useContext } from 'react';
import type { UserWinnings } from '../types';
import useOptimizedData from '../hooks/useOptimizedData';

interface ReferralContextType {
  // Full user data
  userData: UserWinnings | null;
  
  // Loading and error states
  loading: boolean;
  error: string | null;
  
  // Common referral data
  referralCode: string | null;
  directReferrals: number;
  indirectReferrals: number;
  networkSize: number;
  diceEarned: number;
  canRollAgain: boolean;
  
  // Earnings data
  level1Earnings: number;
  level2Earnings: number;
  level3Earnings: number;
  
  // Staking data
  stakedDice: number;
  stakedDton: number;
  
  // Game stats
  totalRolls: number;
  premiumRolls: number;
  regularRolls: number;
  
  // Actions
  refresh: () => Promise<void>;
  isQuotaExceeded: boolean;
}

const ReferralContext = createContext<ReferralContextType | undefined>(undefined);

export const ReferralProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { userData, loading, error, refresh, isQuotaExceeded } = useOptimizedData();

  // Derive all values from userData
  const contextValue: ReferralContextType = {
    // Pass through base data
    userData,
    loading,
    error,
    refresh,
    isQuotaExceeded,

    // Derive referral data
    referralCode: userData?.referralCode || null,
    directReferrals: userData?.networkStats?.directReferrals || 0,
    indirectReferrals: userData?.networkStats?.indirectReferrals || 0,
    networkSize: userData?.networkStats?.totalNetworkSize || 0,
    diceEarned: userData?.referralDiceTokens || 0,
    canRollAgain: (userData?.networkStats?.directReferrals || 0) >= 5,
    
    // Derive earnings data
    level1Earnings: userData?.networkStats?.level1Earnings || 0,
    level2Earnings: userData?.networkStats?.level2Earnings || 0,
    level3Earnings: userData?.networkStats?.level3Earnings || 0,
    
    // Derive staking data
    stakedDice: userData?.staking?.dice || 0,
    stakedDton: userData?.staking?.dton || 0,
    
    // Derive game stats
    totalRolls: userData?.gameStats?.totalRolls || 0,
    premiumRolls: userData?.gameStats?.premiumRolls || 0,
    regularRolls: userData?.gameStats?.regularRolls || 0,
  };

  return (
    <ReferralContext.Provider value={contextValue}>
      {children}
    </ReferralContext.Provider>
  );
};

// Custom hook with type safety
export const useReferral = (): ReferralContextType => {
  const context = useContext(ReferralContext);
  if (!context) {
    throw new Error('useReferral must be used within a ReferralProvider');
  }
  return context;
};

// Specialized hooks for specific data
export const useReferralStats = () => {
  const { directReferrals, indirectReferrals, networkSize, diceEarned } = useReferral();
  return { directReferrals, indirectReferrals, networkSize, diceEarned };
};

export const useEarnings = () => {
  const { level1Earnings, level2Earnings, level3Earnings } = useReferral();
  return { level1Earnings, level2Earnings, level3Earnings };
};

export const useStakingData = () => {
  const { stakedDice, stakedDton } = useReferral();
  return { stakedDice, stakedDton };
};

export const useGameStats = () => {
  const { totalRolls, premiumRolls, regularRolls } = useReferral();
  return { totalRolls, premiumRolls, regularRolls };
};