// src/components/DiceGame/config.ts

export const GAME_CONFIG = {
  ROLL_COOLDOWN: {
    regular: 3 * 60 * 60 * 1000, // 1 hour in milliseconds
    premium: 2 * 60 * 60 * 1000, // 2 hours in milliseconds
  },
  
  CLAIM_FEES: {
    regular: 0,  // No transaction needed for regular rolls
    premium: 200000000,   // 0.2 TON for premium rolls
    stake: 100000000  // 0.1 TON to stake rolling earnings
  } as const,

  // Contract addresses
  TREASURY_ADDRESS: 'UQCwYF61Odjg-q3_Hz3ayqXgKdrdr2jotycDnxGdxWOjfR63',

  // Prize multipliers
  REGULAR_MULTIPLIER: 10,  // Base dice sum × 10
  PREMIUM_MULTIPLIER: 20,  // Base dice sum × 20

  PRIZES: {
    regular: {
      doubleSix: { dton: 0, dice: 500 },   // Only DICE tokens for regular rolls
      double: { dton: 0, dice: 300 },
      lucky: { dton: 0, dice: 200 },
      base: { dton: 0 }
    },
    premium: {
      doubleSix: { dton: 5, dice: 1000 },  // Premium rolls can win both DICE and dTON
      double: { dton: 2, dice: 750 },
      lucky: { dton: 1, dice: 500 },
      base: { dton: 0.5 }
    }
  }
};
