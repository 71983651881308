// src/App.tsx

import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import LandingPage from './components/LandingPage';
import DiceGame from './components/DiceGame';
import DiceNomicsPage from './components/DiceNomicsPage';
import ParticipationPage from './components/ParticipationPage';
import ReferralSystem from './components/ReferralSystem';
import Leaderboard from './components/Leaderboard';
import Header from './components/Header';
import MissionsPage from './components/MissionsPage';
import { TonConnectUIProvider } from '@tonconnect/ui-react';
import { ReferralProvider } from './context/ReferralProvider';


declare global {
  interface TelegramWebApp {
    ready: () => void;
    expand: () => void;
  }

  interface Telegram {
    WebApp?: TelegramWebApp;
  }

  interface Window {
    Telegram?: Telegram;
  }
}

function App() {
  useEffect(() => {
    try {
      if (window.Telegram && window.Telegram.WebApp) {
        const tg = window.Telegram.WebApp;
        tg.ready();
        tg.expand();
      }
    } catch (error) {
      console.error('Failed to initialize Telegram WebApp:', error);
    }
  }, []);

  return (
    <TonConnectUIProvider manifestUrl="https://tondicedrop.com/ton-connect-manifest.json">
      <ReferralProvider>
        <Router>
          <div className="min-h-screen flex flex-col bg-gradient-to-br from-purple-900 via-blue-900 to-indigo-900">
            <Header />
            <main className="flex-grow pb-24 pt-16"> {/* Added padding bottom and top */}
              <Routes>
                <Route path="/" element={<LandingPage />} />
                <Route path="/play" element={<DiceGame />} />
                <Route path="/dicenomics" element={<DiceNomicsPage />} />
                <Route path="/vault" element={<ParticipationPage />} />
                <Route path="/referral" element={<ReferralSystem />} />
                <Route path="/missions" element={<MissionsPage />} />
                <Route path="/leaderboard" element={<Leaderboard />} />
                <Route path="*" element={<div>404 - Page Not Found</div>} />
              </Routes>
            </main>
          </div>
        </Router>
      </ReferralProvider>
    </TonConnectUIProvider>
  );
}

export default App;
