import React from 'react';
import { Link } from 'react-router-dom';
import { Dices, Trophy, Users } from 'lucide-react';

const LandingPage: React.FC = () => {
  return (
    <div className="min-h-screen bg-gradient-to-br from-purple-900 via-blue-900 to-indigo-900 text-white">
      {/* Hero Section */}
      <div className="container mx-auto px-4 pt-20">
        <div className="flex flex-col items-center justify-center min-h-[60vh] text-center">
          <h1 className="text-8xl font-bold mb-6 font-cherry">
            <span className="bg-clip-text text-transparent bg-gradient-to-r from-yellow-400 to-pink-500">
              DICE DROP
            </span>
          </h1>
          <p className="font-['Space_Grotesk'] leading-relaxed text-3xl mb-8 text-purple-200 max-w-2xl">
            The Ultimate TON Gaming Experience - Roll, Win, and Earn Together!
          </p>
          <div className="flex flex-wrap gap-4 justify-center mb-12">
            <Link 
              to="/play" 
              className="bg-gradient-to-r from-yellow-500 to-yellow-600 hover:from-yellow-400 hover:to-yellow-500 
                       text-black font-bold py-4 px-8 rounded-xl text-xl transition duration-300 
                       hover:scale-105 shadow-lg"
            >
              Play Now
            </Link>
            <Link 
              to="/dicenomics" 
              className="bg-gradient-to-r from-purple-600 to-pink-600 hover:from-purple-500 hover:to-pink-500 
                       text-white font-bold py-4 px-8 rounded-xl text-xl transition duration-300 
                       hover:scale-105 shadow-lg"
            >
              Learn More
            </Link>
          </div>

          {/* Features */}
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8 max-w-5xl mx-auto">
            <div className="bg-white/10 backdrop-blur-lg p-8 rounded-xl border border-purple-500/30">
              <div className="text-center">
                <div className="bg-yellow-400/20 p-4 rounded-full w-20 h-20 flex items-center justify-center mx-auto mb-6">
                  <Dices className="w-10 h-10 text-yellow-400" />
                </div>
                <h3 className="text-2xl font-bold mb-4">Roll to Win</h3>
                <p className="font-['Space_Grotesk'] text-purple-200">
                  Roll the dice daily for a chance to win instant TON rewards
                </p>
              </div>
            </div>

            <div className="bg-white/10 backdrop-blur-lg p-8 rounded-xl border border-purple-500/30">
              <div className="text-center">
                <div className="bg-green-400/20 p-4 rounded-full w-20 h-20 flex items-center justify-center mx-auto mb-6">
                  <Users className="w-10 h-10 text-green-400" />
                </div>
                <h3 className="text-2xl font-bold mb-4">Build Network</h3>
                <p className="font-['Space_Grotesk'] text-purple-200">
                  Invite friends to join and earn up to 15% of their winnings
                </p>
              </div>
            </div>

            <div className="bg-white/10 backdrop-blur-lg p-8 rounded-xl border border-purple-500/30">
              <div className="text-center">
                <div className="bg-blue-400/20 p-4 rounded-full w-20 h-20 flex items-center justify-center mx-auto mb-6">
                  <Trophy className="w-10 h-10 text-blue-400" />
                </div>
                <h3 className="text-2xl font-bold mb-4">Level Up</h3>
                <p className="font-['Space_Grotesk'] text-purple-200">
                  Progress through tiers to unlock more daily rolls
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;