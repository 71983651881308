import React, { useEffect, useState, useCallback } from 'react';
import { getUserWinnings, addReferralCode, createUserWinnings } from '../services/firebaseService';
import { useTonAddress } from '@tonconnect/ui-react';
import { Share2, Users, Award, ChevronRight, Copy, RefreshCw, AlertCircle } from 'lucide-react';

const TIER_NAMES = ['Bronze', 'Silver', 'Gold', 'Platinum', 'Diamond'];
const TIER_COLORS = ['from-orange-400', 'from-gray-400', 'from-yellow-400', 'from-blue-400', 'from-purple-400'];

interface ReferralTier {
  name: string;
  requiredReferrals: number;
  rollReward: number;
  directBonus: number;
  indirectBonus: number;
}

const TIERS: ReferralTier[] = [
  { name: 'Bronze', requiredReferrals: 0, rollReward: 1, directBonus: 5, indirectBonus: 0 },
  { name: 'Silver', requiredReferrals: 5, rollReward: 2, directBonus: 7, indirectBonus: 2 },
  { name: 'Gold', requiredReferrals: 15, rollReward: 3, directBonus: 10, indirectBonus: 3 },
  { name: 'Platinum', requiredReferrals: 30, rollReward: 4, directBonus: 12, indirectBonus: 4 },
  { name: 'Diamond', requiredReferrals: 50, rollReward: 5, directBonus: 15, indirectBonus: 5 }
];

const ReferralSystem: React.FC = () => {
  const [userData, setUserData] = useState<any>(null);
  const [referralCode, setReferralCode] = useState<string>('');
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [showTierInfo, setShowTierInfo] = useState<boolean>(false);
  const [copiedToClipboard, setCopiedToClipboard] = useState<boolean>(false);
  const userFriendlyAddress = useTonAddress(true);

  const refreshData = useCallback(async () => {
    if (userFriendlyAddress) {
      try {
        setLoading(true);
        const data = await getUserWinnings(userFriendlyAddress);
        setUserData(data);
      } catch (error) {
        console.error('Error fetching referral data:', error);
        setError('Failed to load referral data');
      } finally {
        setLoading(false);
      }
    }
  }, [userFriendlyAddress]);

  useEffect(() => {
    refreshData();
  }, [refreshData]);

  const getCurrentTier = useCallback(() => {
    if (!userData) return 0;
    const referrals = userData.networkStats?.directReferrals || 0;
    for (let i = TIERS.length - 1; i >= 0; i--) {
      if (referrals >= TIERS[i].requiredReferrals) return i;
    }
    return 0;
  }, [userData]);

  const getNextTier = useCallback(() => {
    const currentTier = getCurrentTier();
    return currentTier < TIERS.length - 1 ? TIERS[currentTier + 1] : null;
  }, [getCurrentTier]);

  const handleReferralCodeSubmit = async () => {
    try {
      if (!userFriendlyAddress) {
        setError('Please connect your wallet first.');
        return;
      }

      await addReferralCode(userFriendlyAddress, referralCode);
      await refreshData();
      setReferralCode('');
      setError(null);
    } catch (error: any) {
      setError(error.message || 'Failed to submit referral code.');
    }
  };

  const copyReferralCode = async () => {
    if (userData?.referralCode) {
      await navigator.clipboard.writeText(userData.referralCode);
      setCopiedToClipboard(true);
      setTimeout(() => setCopiedToClipboard(false), 2000);
    }
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-gradient-to-br from-purple-900 via-blue-900 to-indigo-900 text-white flex items-center justify-center">
        <RefreshCw className="w-8 h-8 animate-spin" />
      </div>
    );
  }

  const currentTier = getCurrentTier();
  const nextTier = getNextTier();

  return (
    <div className="min-h-screen bg-gradient-to-br from-purple-900 via-blue-900 to-indigo-900 text-white">
      <div className="container mx-auto px-4 py-8 space-y-6">
        {/* Current Tier Status */}
        <div className={`bg-gradient-to-r ${TIER_COLORS[currentTier]} to-purple-900 p-6 rounded-xl shadow-xl`}>
          <div className="flex items-center justify-between mb-4">
            <div>
              <h2 className="text-xl font-bold">{TIER_NAMES[currentTier]} Tier</h2>
              <p className="text-sm opacity-75">Your Current Level</p>
            </div>
            <Award className="w-12 h-12" />
          </div>
          <div className="grid grid-cols-2 gap-4 mt-4">
            <div className="bg-black bg-opacity-20 p-3 rounded-lg">
              <p className="text-sm">Available Rolls</p>
              <p className="text-2xl font-bold">{userData?.rollsAvailable || 0}</p>
            </div>
            <div className="bg-black bg-opacity-20 p-3 rounded-lg">
              <p className="text-sm">Total Referrals</p>
              <p className="text-2xl font-bold">{userData?.networkStats?.directReferrals || 0}</p>
            </div>
          </div>
          {nextTier && (
            <div className="mt-4 bg-black bg-opacity-20 p-3 rounded-lg">
              <p className="text-sm">Next Tier Progress</p>
              <div className="flex items-center gap-2">
                <div className="flex-1 bg-gray-700 rounded-full h-2">
                  <div
                    className="bg-yellow-400 h-2 rounded-full"
                    style={{
                      width: `${(userData?.networkStats?.directReferrals / nextTier.requiredReferrals) * 100}%`
                    }}
                  />
                </div>
                <span className="text-sm whitespace-nowrap">
                  {nextTier.requiredReferrals - (userData?.networkStats?.directReferrals || 0)} more to {nextTier.name}
                </span>
              </div>
            </div>
          )}
        </div>

        {/* Referral Network Stats */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div className="bg-white bg-opacity-10 p-6 rounded-xl">
            <div className="flex items-center gap-2 mb-4">
              <Share2 className="w-6 h-6" />
              <h2 className="text-xl font-bold">Your Referral Code</h2>
            </div>
            <div className="flex items-center gap-2 bg-purple-900 p-3 rounded-lg">
              <code className="flex-1 font-mono text-lg">
                {userData?.referralCode || 'Generate your code'}
              </code>
              <button
                onClick={copyReferralCode}
                className="bg-purple-700 hover:bg-purple-600 p-2 rounded-lg transition-colors"
              >
                {copiedToClipboard ? 'Copied!' : <Copy className="w-5 h-5" />}
              </button>
            </div>
            {!userData?.referralCode && (
              <button
                onClick={() => createUserWinnings(userFriendlyAddress)}
                className="mt-4 w-full bg-green-600 hover:bg-green-500 py-2 rounded-lg transition-colors"
              >
                Generate Code
              </button>
            )}
          </div>

          <div className="bg-white bg-opacity-10 p-6 rounded-xl">
  <div className="flex items-center gap-2 mb-4">
    <Users className="w-6 h-6" />
    <h2 className="text-xl font-bold">Network Stats</h2>
  </div>
  <div className="space-y-3">
    <div className="flex justify-between p-3 bg-purple-900 bg-opacity-50 rounded-lg group">
      <span className="flex items-center gap-2">
        Direct Referrals
        <div className="cursor-help" title="Users who directly used your referral code">
          <AlertCircle className="w-4 h-4 opacity-75" />
        </div>
      </span>
      <span className="font-bold">{userData?.networkStats?.directReferrals || 0}</span>
    </div>
    <div className="flex justify-between p-3 bg-purple-900 bg-opacity-50 rounded-lg">
      <span className="flex items-center gap-2">
        Indirect Referrals
        <div className="cursor-help" title="Users in your network beyond direct referrals (Level 2 & 3)">
          <AlertCircle className="w-4 h-4 opacity-75" />
        </div>
      </span>
      <span className="font-bold">{userData?.networkStats?.indirectReferrals || 0}</span>
    </div>
    <div className="flex justify-between p-3 bg-purple-900 bg-opacity-50 rounded-lg">
      <span className="flex items-center gap-2">
        Total Network Size
        <div className="cursor-help" title="Combined total of direct and indirect referrals">
          <AlertCircle className="w-4 h-4 opacity-75" />
        </div>
      </span>
      <span className="font-bold">{userData?.networkStats?.totalNetworkSize || 0}</span>
    </div>
  </div>
          </div>
        </div>

        {/* Earnings Overview */}
        <div className="bg-white bg-opacity-10 p-6 rounded-xl">
          <h2 className="text-xl font-bold mb-4">Earnings Overview</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            <div className="bg-purple-900 bg-opacity-50 p-4 rounded-lg">
              <p className="text-sm opacity-75 flex items-center gap-2">
                Level 1 Earnings
                <span 
                  className="cursor-help"
                  title="Earnings from direct referrals">
                  <AlertCircle className="w-4 h-4" />
                </span>
              </p>
              <p className="text-xl font-bold">
                {(userData?.networkStats?.level1Earnings || 0).toLocaleString()} $DICE
              </p>
            </div>
            <div className="bg-purple-900 bg-opacity-50 p-4 rounded-lg">
              <p className="text-sm opacity-75 flex items-center gap-2">
                Level 2 Earnings
                <span 
                  className="cursor-help"
                  title="Earnings from your referrals' referrals">
                  <AlertCircle className="w-4 h-4" />
                </span>
              </p>
              <p className="text-xl font-bold">
                {(userData?.networkStats?.level2Earnings || 0).toLocaleString()} $DICE
              </p>
            </div>
            <div className="bg-purple-900 bg-opacity-50 p-4 rounded-lg">
              <p className="text-sm opacity-75 flex items-center gap-2">
                Level 3 Earnings
                <span 
                  className="cursor-help"
                  title="Earnings from level 2's referrals">
                  <AlertCircle className="w-4 h-4" />
                </span>
              </p>
              <p className="text-xl font-bold">
                {(userData?.networkStats?.level3Earnings || 0).toLocaleString()} $DICE
              </p>
            </div>
          </div>

          {/* Network Visualization */}
          <div className="mt-6 p-4 bg-purple-900 bg-opacity-30 rounded-lg">
            <h3 className="text-lg font-semibold mb-3">Network Structure</h3>
            <div className="flex items-center justify-between text-sm">
              <div className="text-center">
                <div className="w-20 h-20 rounded-full bg-yellow-400 bg-opacity-20 flex items-center justify-center mb-2">
                  You
                </div>
                <p>Level 0</p>
              </div>
              <div className="flex-1 h-px bg-white opacity-20 relative">
                <div className="absolute -top-6 w-full text-center">
                  {userData?.networkStats?.directReferrals || 0} referrals
                </div>
              </div>
              <div className="text-center">
                <div className="w-20 h-20 rounded-full bg-green-400 bg-opacity-20 flex items-center justify-center mb-2">
                  {userData?.networkStats?.directReferrals || 0}
                </div>
                <p>Level 1</p>
              </div>
              <div className="flex-1 h-px bg-white opacity-20 relative">
                <div className="absolute -top-6 w-full text-center">
                  {userData?.networkStats?.indirectReferrals || 0} indirect
                </div>
              </div>
              <div className="text-center">
                <div className="w-20 h-20 rounded-full bg-blue-400 bg-opacity-20 flex items-center justify-center mb-2">
                  {userData?.networkStats?.totalNetworkSize || 0}
                </div>
                <p>Total</p>
              </div>
            </div>
          </div>
        </div>

        {/* Enter Referral Code */}
        {!userData?.referredBy && (
          <div className="bg-white bg-opacity-10 p-6 rounded-xl">
            <h2 className="text-xl font-bold mb-4">Enter Referral Code</h2>
            <div className="flex flex-col sm:flex-row gap-2">
              <input
                type="text"
                placeholder="Enter a referral code"
                value={referralCode}
                onChange={(e) => setReferralCode(e.target.value)}
                className="flex-1 bg-purple-900 bg-opacity-50 px-4 py-2 rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500"
              />
              <button
                onClick={handleReferralCodeSubmit}
                className="w-full sm:w-auto bg-green-600 hover:bg-green-500 px-6 py-2 rounded-lg transition-colors whitespace-nowrap"
              >
                Submit Code
              </button>
            </div>
            {error && <p className="mt-2 text-red-400 text-sm">{error}</p>}
          </div>
        )}

        {/* Referral Tiers Info */}
        <div className="bg-white bg-opacity-10 p-6 rounded-xl">
          <button
            onClick={() => setShowTierInfo(!showTierInfo)}
            className="flex items-center justify-between w-full"
          >
            <h2 className="text-xl font-bold">Referral Tiers</h2>
            <ChevronRight
              className={`w-6 h-6 transition-transform ${showTierInfo ? 'rotate-90' : ''}`}
            />
          </button>
          {showTierInfo && (
            <div className="mt-4 space-y-4">
              {TIERS.map((tier, index) => (
                <div
                  key={tier.name}
                  className={`bg-gradient-to-r ${TIER_COLORS[index]} to-purple-900 p-4 rounded-lg`}
                >
                  <h3 className="font-bold mb-2">{tier.name}</h3>
                  <div className="grid grid-cols-2 gap-2 text-sm">
                    <div>Required Referrals: {tier.requiredReferrals}</div>
                    <div>Rolls Per Unlock: {tier.rollReward}</div>
                    <div>Direct Bonus: {tier.directBonus}%</div>
                    <div>Indirect Bonus: {tier.indirectBonus}%</div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ReferralSystem;