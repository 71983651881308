import React, { useState, useEffect } from 'react';
import { Lock, TrendingUp, Gift, Award } from 'lucide-react';
import Swal from 'sweetalert2';
import type { StakingVaultProps, RollingEarning } from '../types';
import { stakeRollingEarnings } from '../services/firebaseService';
import { getGlobalStakingStats, updateGlobalStakingStats, calculateDynamicAPY } from '../services/stakingService';
import { displayEnhancer } from '../services/displayEnhancer';
import { STAKING_CONFIG } from '../config/staking.config';
import { GAME_CONFIG } from '../components/DiceGame/config';

const TIER_NAMES = ['Bronze', 'Silver', 'Gold', 'Platinum', 'Diamond'];

interface StakingStats {
  diceAPY: number;
  dtonAPY: number;
  earlyStakerBonus: number;
  tierMultiplier: number;
  effectiveAPY: number;
  totalStakedDice: number;
  totalStakedDton: number;
  remainingRewards: number;
  yourPosition?: number;
}

const formatEarnings = (amount: number): string => {
  if (amount <= 0) return '0.0000';
  if (amount < 0.0001) return '0.0001';
  return amount.toFixed(4);
};

const StakingVault: React.FC<StakingVaultProps> = ({
  userWinnings,
  userAddress,
  tonConnectUI,
  onStakingComplete,
}) => {
  const [isStaking, setIsStaking] = useState<boolean>(false);
  const [earnedInterest, setEarnedInterest] = useState<{ dice: number; dton: number }>({
    dice: 0,
    dton: 0,
  });
  const [stakeDiceAmount, setStakeDiceAmount] = useState<number>(0);
  const [stakeDtonAmount, setStakeDtonAmount] = useState<number>(0);
  const [stakingStats, setStakingStats] = useState<StakingStats | null>(null);

  function getTotalUnstaked(type: 'DICE' | 'DTON'): number {
    return (userWinnings?.rollingEarnings || [])
      .filter((e: RollingEarning) => e.tokenType === type && !e.staked)
      .reduce((sum: number, e: RollingEarning) => sum + (e.amount || 0), 0);
  }

  useEffect(() => {
    const fetchStakingStats = async () => {
      try {
        const currentStats = await getGlobalStakingStats();
        const enhancedStats = displayEnhancer.enhanceGlobalStats(currentStats);
        const currentPosition = userWinnings?.stakingPosition || enhancedStats.totalStakers + 1;

        const currentApyData = calculateDynamicAPY(
          currentPosition,
          currentStats,
          userWinnings?.currentTier || 0
        );

        setStakingStats({
          ...currentApyData,
          totalStakedDice: enhancedStats.totalStakedDice,
          totalStakedDton: enhancedStats.totalStakedDton,
          remainingRewards: enhancedStats.remainingRewards,
          yourPosition: currentPosition
        });
      } catch (error) {
        console.error('Failed to fetch staking stats:', error);
      }
    };

    fetchStakingStats();
    const interval = setInterval(fetchStakingStats, 60000);
    return () => clearInterval(interval);
  }, [userWinnings]);

  useEffect(() => {
    const calculateInterest = () => {
      if (!userWinnings?.staking?.lastStakeTime || !stakingStats) return;

      const timeElapsed =
        (Date.now() - userWinnings.staking.lastStakeTime) / (1000 * 60 * 60 * 24 * 365);
      const diceRate = stakingStats.effectiveAPY;
      const dtonRate = stakingStats.dtonAPY;

      setEarnedInterest({
        dice: userWinnings.staking.dice * (diceRate / 100) * timeElapsed,
        dton: userWinnings.staking.dton * (dtonRate / 100) * timeElapsed,
      });
    };

    calculateInterest();
    const interval = setInterval(calculateInterest, 1000);
    return () => clearInterval(interval);
  }, [userWinnings, stakingStats]);

  const handleStake = async () => {
    if (!userAddress || isStaking) return;

    try {
      setIsStaking(true);
      const unstakedDice = getTotalUnstaked('DICE');
      const unstakedDton = getTotalUnstaked('DTON');

      if (stakeDiceAmount > unstakedDice || stakeDtonAmount > unstakedDton) {
        throw new Error('Insufficient balance');
      }

      const currentStats = await getGlobalStakingStats();
      const isNewStaker = !userWinnings.staking || userWinnings.staking.dice === 0;
      const newTotalStaked = currentStats.totalStakedDice + stakeDiceAmount;

      if (newTotalStaked > STAKING_CONFIG.TOTAL_STAKING_ALLOCATION) {
        throw new Error('Staking limit reached');
      }

      const apyData = calculateDynamicAPY(
        isNewStaker ? currentStats.totalStakers + 1 : stakingStats?.yourPosition || 0,
        currentStats,
        userWinnings?.currentTier || 0
      );

      const confirmStake = await Swal.fire({
        title: 'Confirm Staking',
        html: `
          <div class="space-y-4">
            <div>
              Staking:
              ${stakeDiceAmount > 0 ? `<br>${stakeDiceAmount.toFixed(2)} DICE at ${apyData.effectiveAPY.toFixed(1)}% APY` : ''}
              ${stakeDtonAmount > 0 ? `<br>${stakeDtonAmount.toFixed(2)} dTON at ${apyData.dtonAPY.toFixed(1)}% APY` : ''}
            </div>
            <div class="text-sm opacity-75">
              ${isNewStaker ? 'You will be staker #' + (currentStats.totalStakers + 1) : ''}
              ${apyData.earlyStakerBonus > 1 ? `<br>Early bonus: ${((apyData.earlyStakerBonus - 1) * 100).toFixed(0)}%` : ''}
              ${apyData.tierMultiplier > 1 ? `<br>Tier bonus: ${((apyData.tierMultiplier - 1) * 100).toFixed(0)}%` : ''}
            </div>
            <div class="mt-4">Cost: 0.1 TON</div>
          </div>
        `,
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Stake',
        cancelButtonText: 'Cancel',
      });

      if (!confirmStake.isConfirmed) {
        setIsStaking(false);
        return;
      }

      await tonConnectUI.sendTransaction({
        validUntil: Date.now() + 5 * 60 * 1000,
        messages: [
          {
            address: GAME_CONFIG.TREASURY_ADDRESS,
            amount: GAME_CONFIG.CLAIM_FEES.stake.toString(),
          },
        ],
      });

      const stakeData = {
        dice: stakeDiceAmount,
        dton: stakeDtonAmount,
        timestamp: Date.now(),
        accruedInterest: earnedInterest,
      };

      await updateGlobalStakingStats(stakeDiceAmount, isNewStaker);
      await stakeRollingEarnings(userAddress, stakeData);

      setStakeDiceAmount(0);
      setStakeDtonAmount(0);
      setEarnedInterest({ dice: 0, dton: 0 });

      const newStats = await getGlobalStakingStats();
      const enhancedNewStats = displayEnhancer.enhanceGlobalStats(newStats);
      const newPosition = isNewStaker ? enhancedNewStats.totalStakers : stakingStats?.yourPosition || 0;
      const newApyData = calculateDynamicAPY(newPosition, newStats, userWinnings?.currentTier || 0);

      setStakingStats({
        ...newApyData,
        totalStakedDice: enhancedNewStats.totalStakedDice,
        totalStakedDton: enhancedNewStats.totalStakedDton,
        remainingRewards: enhancedNewStats.remainingRewards,
        yourPosition: newPosition
      });

      await onStakingComplete();

      Swal.fire({
        title: 'Staked Successfully!',
        html: `Staking rates:<br>DICE: ${newApyData.effectiveAPY.toFixed(1)}% APY<br>dTON: ${newApyData.dtonAPY.toFixed(1)}% APY`,
        icon: 'success',
      });
    } catch (error: any) {
      console.error('Staking failed:', error);
      Swal.fire('Error', error.message || 'Failed to stake tokens', 'error');
    } finally {
      setIsStaking(false);
    }
  };

  return (
    <div className="max-w-4xl mx-auto space-y-6">
      {/* Airdrop Staking Info */}
      <div className="bg-white/5 p-6 rounded-xl">
        <div className="flex items-center gap-3 mb-3">
          <Gift className="w-6 h-6" />
          <div>
            <h2 className="text-xl font-bold">Airdrop Staking</h2>
            <p className="text-sm opacity-75">
              Lock your earnings to secure your spot in the upcoming airdrop! Over{' '}
              {stakingStats?.effectiveAPY.toFixed(1)}% APY. Level up and stake early for higher
              rates! Only vaulted tokens will be eligible for the DICE DROP.
            </p>
          </div>
        </div>
      </div>
  
      {/* Staking Boosters */}
      <div className="bg-white/5 p-6 rounded-xl">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-xl font-bold">Your staking boosters</h2>
          <div className="text-2xl font-bold text-yellow-400">
            {stakingStats?.effectiveAPY.toFixed(1)}% APY
          </div>
        </div>
  
        {/* Booster Cards */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <div className="bg-gradient-to-br from-blue-600 to-indigo-600 p-4 rounded-xl text-center">
            <div className="flex items-center justify-center gap-2 mb-2">
              <TrendingUp className="w-5 h-5" />
              <span className="font-semibold">Base APY</span>
            </div>
            <div className="text-3xl font-bold">
              {stakingStats?.diceAPY.toFixed(1)}%
            </div>
          </div>
  
          <div className="bg-gradient-to-br from-orange-500 to-amber-600 p-4 rounded-xl text-center">
            <div className="flex items-center justify-center gap-2 mb-2">
              <Gift className="w-5 h-5" />
              <span className="font-semibold">Early Bonus</span>
            </div>
            <div className="text-3xl font-bold">
              {((stakingStats?.earlyStakerBonus || 1) - 1) * 100}%
            </div>
            <div className="text-sm mt-1">Position #{stakingStats?.yourPosition || '??'}</div>
          </div>
  
          <div className="bg-gradient-to-br from-purple-500 to-pink-600 p-4 rounded-xl text-center">
            <div className="flex items-center justify-center gap-2 mb-2">
              <Award className="w-5 h-5" />
              <span className="font-semibold">Tier Bonus</span>
            </div>
            <div className="text-3xl font-bold">
              {((stakingStats?.tierMultiplier || 1) - 1) * 100}%
            </div>
            <div className="text-sm mt-1">{TIER_NAMES[userWinnings?.currentTier || 0]}</div>
          </div>
        </div>
      </div>
  
      {/* Your Vault */}
      <div className="bg-white/5 p-6 rounded-xl">
        <h2 className="text-xl font-bold mb-4">Your Vault</h2>
        <div className="space-y-4">
          <div className="bg-purple-900/20 p-4 rounded-lg">
            <div className="flex justify-between">
              <span className="text-sm opacity-75">Staked DICE</span>
              <span className="text-xs text-green-400">
                {stakingStats?.effectiveAPY.toFixed(1)}% APY
              </span>
            </div>
            <div className="text-2xl font-bold mt-1">
              {(userWinnings?.staking?.dice || 0).toFixed(4)}
            </div>
            <div className="text-green-400 text-sm flex items-center gap-1 mt-1">
              <TrendingUp className="w-4 h-4" />
              +{formatEarnings(earnedInterest.dice)}
            </div>
          </div>
  
          <div className="bg-purple-900/20 p-4 rounded-lg">
            <div className="flex justify-between">
              <span className="text-sm opacity-75">Staked dTON</span>
              <span className="text-xs text-green-400">
                {stakingStats?.dtonAPY.toFixed(1)}% APY
              </span>
            </div>
            <div className="text-2xl font-bold mt-1">
              {(userWinnings?.staking?.dton || 0).toFixed(4)}
            </div>
            <div className="text-green-400 text-sm flex items-center gap-1 mt-1">
              <TrendingUp className="w-4 h-4" />
              +{formatEarnings(earnedInterest.dton)}
            </div>
          </div>
  
          <div className="bg-purple-900/20 p-4 rounded-lg">
            <div className="flex justify-between mb-2">
              <span className="text-sm opacity-75">7 Day Rolling Earnings</span>
              <span className="text-xs text-green-400">Available to Stake</span>
            </div>
            <div className="grid grid-cols-2 gap-4">
              <div>
                <div className="text-sm opacity-75">DICE</div>
                <div className="text-xl font-bold">{formatEarnings(getTotalUnstaked('DICE'))}</div>
              </div>
              <div>
                <div className="text-sm opacity-75">dTON</div>
                <div className="text-xl font-bold">{formatEarnings(getTotalUnstaked('DTON'))}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
  
      {/* Stake Form */}
      <div className="bg-white/5 p-6 rounded-xl">
        <h2 className="text-xl font-bold mb-4">Stake for Airdrop</h2>
        <div className="space-y-4">
          <div>
            <div className="flex justify-between mb-2">
              <span className="text-sm opacity-75">DICE Amount</span>
              <span className="text-sm">Available: {getTotalUnstaked('DICE').toFixed(4)}</span>
            </div>
            <input
              type="number"
              value={stakeDiceAmount}
              onChange={(e) =>
                setStakeDiceAmount(Math.min(Number(e.target.value), getTotalUnstaked('DICE')))
              }
              className="w-full bg-purple-900/20 p-3 rounded-lg text-lg"
              placeholder="Enter DICE amount"
            />
          </div>
  
          <div>
            <div className="flex justify-between mb-2">
              <span className="text-sm opacity-75">dTON Amount</span>
              <span className="text-sm">Available: {getTotalUnstaked('DTON').toFixed(4)}</span>
            </div>
            <input
              type="number"
              value={stakeDtonAmount}
              onChange={(e) =>
                setStakeDtonAmount(Math.min(Number(e.target.value), getTotalUnstaked('DTON')))
              }
              className="w-full bg-purple-900/20 p-3 rounded-lg text-lg"
              placeholder="Enter dTON amount"
            />
          </div>
  
          <div className="flex gap-2">
            <button
              onClick={() => {
                setStakeDiceAmount(getTotalUnstaked('DICE'));
                setStakeDtonAmount(getTotalUnstaked('DTON'));
              }}
              className="flex-1 bg-purple-600 hover:bg-purple-700 p-3 rounded-lg text-sm"
            >
              Max
            </button>
            <button
              onClick={handleStake}
              disabled={
                isStaking ||
                (stakeDiceAmount <= 0 && stakeDtonAmount <= 0) ||
                stakeDiceAmount > getTotalUnstaked('DICE') ||
                stakeDtonAmount > getTotalUnstaked('DTON')
              }
              className="flex-2 bg-green-600 hover:bg-green-700 p-3 rounded-lg disabled:opacity-50 
                         flex items-center justify-center gap-2"
            >
              {isStaking ? (
                <>Staking...</>
              ) : (
                <>
                  <Lock className="w-4 h-4" />
                  Stake for Airdrop (0.1 TON)
                </>
              )}
            </button>
          </div>
        </div>
      </div>
  
      {/* Staking Progress */}
      <div className="bg-white/5 p-6 rounded-xl">
        <h2 className="text-xl font-bold mb-4">Staking Rewards</h2>
        <div className="space-y-4">
          <div>
            <div className="flex justify-between text-sm mb-2">
              <span>Total DICE Staked</span>
              <span>{(stakingStats?.totalStakedDice || 0).toLocaleString()} DICE</span>
            </div>
            <div className="bg-white/10 h-2 rounded-full overflow-hidden">
              <div
                className="bg-gradient-to-r from-purple-500 to-pink-500 h-full transition-all duration-1000"
                style={{
                  width: `${
                    ((stakingStats?.totalStakedDice || 0) /
                      STAKING_CONFIG.TOTAL_STAKING_ALLOCATION) *
                    100
                  }%`,
                }}
              />
            </div>
          </div>
  
          <div>
            <div className="flex justify-between text-sm mb-2">
              <span>Remaining Rewards</span>
              <span>{(stakingStats?.remainingRewards || 0).toLocaleString()} / 200M DICE</span>
            </div>
            <div className="bg-white/10 h-2 rounded-full overflow-hidden">
              <div
                className="bg-gradient-to-r from-yellow-500 to-orange-500 h-full transition-all duration-1000"
                style={{
                  width: `${
                    ((stakingStats?.remainingRewards || 0) /
                      STAKING_CONFIG.TOTAL_STAKING_ALLOCATION) *
                    100
                  }%`,
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StakingVault;