// src/hooks/useOptimizedData.ts

import { useState, useEffect, useCallback } from 'react';
import { useTonAddress } from '@tonconnect/ui-react';
import { getUserWinnings } from '../services/firebaseService';
import { cacheService } from '../services/cacheService';
import type { UserWinnings } from '../types';

export const useOptimizedData = () => {
  const userAddress = useTonAddress(true);
  const [userData, setUserData] = useState<UserWinnings | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const fetchData = useCallback(async (force = false) => {
    if (!userAddress) {
      setLoading(false);
      return;
    }

    try {
      // Check cache first unless force refresh
      if (!force) {
        const cached = cacheService.get<UserWinnings>(`userData_${userAddress}`);
        if (cached) {
          setUserData(cached);
          setLoading(false);
          return;
        }
      }

      setLoading(true);
      const data = await getUserWinnings(userAddress);
      
      // Cache the result
      cacheService.set(`userData_${userAddress}`, data);
      setUserData(data);
      setError(null);

    } catch (err: any) {
      console.error('Error fetching data:', err);
      setError(err.message);

      // If quota exceeded, try to use cached data
      if (err.message.includes('Quota exceeded')) {
        const cached = cacheService.get<UserWinnings>(
          `userData_${userAddress}`, 
          60 * 60 * 1000 // Use longer cache duration for quota errors
        );
        if (cached) {
          setUserData(cached);
          setError('Using cached data (quota limit reached)');
        }
      }
    } finally {
      setLoading(false);
    }
  }, [userAddress]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const refresh = useCallback(() => fetchData(true), [fetchData]);

  return {
    userData,
    loading,
    error,
    refresh,
    isQuotaExceeded: error?.includes('Quota exceeded') || false
  };
};

export default useOptimizedData;