// src/services/firebaseService.ts

import { db } from '../firebase';
import {
  doc,
  setDoc,
  getDoc,
  updateDoc,
  collection,
  query,
  where,
  getDocs,
  arrayUnion,
  increment,
  runTransaction,
  Transaction,
  WriteBatch,
  writeBatch,
  orderBy,
  limit,
} from 'firebase/firestore';
import type {
  RollResult,
  UserWinnings,
  RollingEarning,
  StakedTokens,
} from '../types';

// Helper Functions
const generateUniqueReferralCode = (address: string): string => {
  const prefix = address.slice(0, 4).toUpperCase();
  const randomPart = Math.random().toString(36).substring(2, 6).toUpperCase();
  return `${prefix}${randomPart}`;
};

interface TierConfig {
  requiredReferrals: number;
  rollReward: number;
  directBonusPercent: number;
  indirectBonusPercent: number;
}

// Tier Configuration
const TIER_CONFIGS: TierConfig[] = [
  { requiredReferrals: 0, rollReward: 1, directBonusPercent: 5, indirectBonusPercent: 0 },
  { requiredReferrals: 5, rollReward: 2, directBonusPercent: 7, indirectBonusPercent: 2 },
  { requiredReferrals: 15, rollReward: 3, directBonusPercent: 10, indirectBonusPercent: 3 },
  { requiredReferrals: 30, rollReward: 4, directBonusPercent: 12, indirectBonusPercent: 4 },
  { requiredReferrals: 50, rollReward: 5, directBonusPercent: 15, indirectBonusPercent: 5 },
];

const TIER_YIELDS = [
  5,    // Bronze - 5% base APY
  7.5,  // Silver - 7.5% APY
  10,   // Gold - 10% APY
  12.5, // Platinum - 12.5% APY
  15,   // Diamond - 15% APY
];

// Calculate Tier based on referrals
const calculateTier = (directReferrals: number): number => {
  for (let i = TIER_CONFIGS.length - 1; i >= 0; i--) {
    if (directReferrals >= TIER_CONFIGS[i].requiredReferrals) {
      return i;
    }
  }
  return 0;
};

// Calculate available rolls based on referrals
export const calculateRollsAvailable = (directReferrals: number): number => {
  const tier = calculateTier(directReferrals);
  return TIER_CONFIGS[tier].rollReward;
};

// Get Yield Rate based on tier
const getYieldRate = (tier: number): number => {
  return TIER_YIELDS[tier] || 1; // Default to 1% if tier is undefined
};

// Create User Winnings
export const createUserWinnings = async (walletAddress: string): Promise<void> => {
  try {
    const userRef = doc(db, 'userWinnings', walletAddress);
    const userDoc = await getDoc(userRef);

    if (!userDoc.exists()) {
      const referralCode = generateUniqueReferralCode(walletAddress);
      const initialData: UserWinnings = {
        tonWinnings: 0,
        diceTokens: 0,
        referralDiceTokens: 0,
        directReferralEarnings: 0,
        indirectReferralEarnings: 0,
        lastUpdated: Date.now(),
        claimHistory: [],
        referralCode,
        rollsAvailable: calculateRollsAvailable(0), // Initialize based on 0 referrals
        referrals: {},
        rollingEarnings: [],
        staking: {
          dton: 0,
          dice: 0,
          yieldRate: 1,
          lastStakeTime: Date.now(),
        },
        networkStats: {
          directReferrals: 0,
          indirectReferrals: 0,
          totalNetworkSize: 0,
          level1Earnings: 0,
          level2Earnings: 0,
          level3Earnings: 0,
        },
        gameStats: {
          premiumRolls: 0,
          regularRolls: 0,
          doubleSixes: 0,
          luckyRolls: 0,
          totalRolls: 0,
          highestWin: 0,
          bestRollTimestamp: null,
        },
        currentTier: 0,
        lastRegularRollTimestamp: null, // Initialize
        lastPremiumRollTimestamp: null, // Initialize
        lastDailyDiceRoll: null,
        usedTierRolls: [], // Initialize as empty array
      };
      await setDoc(userRef, initialData);
    }
  } catch (error) {
    console.error('Error creating user winnings:', error);
    throw new Error('Failed to create user winnings.');
  }
};

// Get User Winnings
export const getUserWinnings = async (walletAddress: string): Promise<UserWinnings> => {
  try {
    console.log('getUserWinnings called with walletAddress:', walletAddress);
    const userRef = doc(db, 'userWinnings', walletAddress);
    const userDoc = await getDoc(userRef);

    if (userDoc.exists()) {
      const data = userDoc.data() as UserWinnings;
      // Ensure usedTierRolls is defined
      if (!Array.isArray(data.usedTierRolls)) {
        data.usedTierRolls = [];
        await updateDoc(userRef, { usedTierRolls: [] });
      }
      return data;
    } else {
      await createUserWinnings(walletAddress);
      const newDoc = await getDoc(userRef);
      return newDoc.data() as UserWinnings;
    }
  } catch (error: any) {
    console.error('Error fetching user winnings:', error);
    throw error; // Re-throw the original error to see the full message
  }
};


// Decrement Rolls Available
/**
 * Decrements the rollsAvailable by a specified amount.
 * @param walletAddress - The user's wallet address.
 * @param decrementBy - Number of rolls to decrement (default is 1).
 */
export const decrementRollsAvailable = async (
  walletAddress: string,
  decrementBy: number = 1
): Promise<void> => {
  try {
    const userRef = doc(db, 'userWinnings', walletAddress);
    await runTransaction(db, async (transaction: Transaction) => {
      const userDoc = await transaction.get(userRef);
      if (!userDoc.exists()) {
        throw new Error('User not found.');
      }

      const userData = userDoc.data() as UserWinnings;

      if (userData.rollsAvailable < decrementBy) {
        throw new Error('Insufficient rolls available.');
      }

      transaction.update(userRef, {
        rollsAvailable: increment(-decrementBy),
        lastUpdated: Date.now(),
      });
    });
  } catch (error: any) {
    console.error('Error decrementing rollsAvailable:', error);
    throw new Error(error.message || 'Failed to decrement rollsAvailable.');
  }
};

// Increment Rolls Available
/**
 * Increments the rollsAvailable by a specified amount.
 * @param walletAddress - The user's wallet address.
 * @param incrementBy - Number of rolls to increment (default is 1).
 */
export const incrementRollsAvailable = async (
  walletAddress: string,
  incrementBy: number = 1
): Promise<void> => {
  try {
    const userRef = doc(db, 'userWinnings', walletAddress);
    await runTransaction(db, async (transaction: Transaction) => {
      const userDoc = await transaction.get(userRef);
      if (!userDoc.exists()) {
        throw new Error('User not found.');
      }

      transaction.update(userRef, {
        rollsAvailable: increment(incrementBy),
        lastUpdated: Date.now(),
      });
    });
  } catch (error: any) {
    console.error('Error incrementing rollsAvailable:', error);
    throw new Error(error.message || 'Failed to increment rollsAvailable.');
  }
};

// Update Referral Chain Earnings - Fixed Version
export const updateReferralChainEarnings = async (
  userAddress: string,
  rollResult: RollResult,
  level: number = 1,
  maxLevel: number = 3
): Promise<void> => {
  if (level > maxLevel) return;

  try {
    const userDoc = await getDoc(doc(db, 'userWinnings', userAddress));
    const userData = userDoc.data() as UserWinnings;

    if (userData?.referredBy) {
      const referrerQuery = query(
        collection(db, 'userWinnings'),
        where('referralCode', '==', userData.referredBy)
      );
      const querySnapshot = await getDocs(referrerQuery);

      if (!querySnapshot.empty) {
        const referrerDoc = querySnapshot.docs[0];
        const referrerId = referrerDoc.id;
        const referrerData = referrerDoc.data() as UserWinnings;
        const referrerTier = calculateTier(referrerData.networkStats?.directReferrals || 0);

        const tierConfig = TIER_CONFIGS[referrerTier];
        const bonusPercent =
          level === 1 ? tierConfig.directBonusPercent : tierConfig.indirectBonusPercent;

        const bonus = (rollResult.diceAmount * bonusPercent) / 100;

        const updates: any = {
          referralDiceTokens: increment(bonus),
          [`networkStats.level${level}Earnings`]: increment(bonus),
          lastUpdated: Date.now(),
        };

        // Update direct/indirect earnings and referral counts
        if (level === 1) {
          updates.directReferralEarnings = increment(bonus);
          updates['networkStats.directReferrals'] = increment(1);
          updates['networkStats.totalNetworkSize'] = increment(1);
        } else {
          updates.indirectReferralEarnings = increment(bonus);
          
          // Check if this is a new indirect referral
          const referralHistoryRef = doc(db, `referralHistory/${referrerId}/levels/level${level}`);
          const referralHistoryDoc = await getDoc(referralHistoryRef);
          
          if (!referralHistoryDoc.exists() || !referralHistoryDoc.data()?.addresses?.includes(userAddress)) {
            updates['networkStats.indirectReferrals'] = increment(1);
            updates['networkStats.totalNetworkSize'] = increment(1); // Also increment total for new indirect referrals
            
            // Track this address
            await setDoc(referralHistoryRef, {
              addresses: arrayUnion(userAddress)
            }, { merge: true });
          }
        }

        await updateDoc(doc(db, 'userWinnings', referrerId), updates);
        
        // Continue up the chain
        await updateReferralChainEarnings(referrerId, rollResult, level + 1, maxLevel);
      }
    }
  } catch (error: any) {
    console.error('Error updating referral chain:', error);
    throw new Error('Failed to update referral chain earnings.');
  }
};


interface LeaderboardEntry extends UserWinnings {
  address: string;
}

// Get Leaderboards
export const getLeaderboards = async () => {
  try {
    const winningsCollection = collection(db, 'userWinnings');
    const snapshot = await getDocs(winningsCollection);

    const entries: LeaderboardEntry[] = snapshot.docs.map((doc) => ({
      address: doc.id,
      ...(doc.data() as UserWinnings),
    }));

    // Helper function to calculate rolling earnings
    const calculateRollingEarnings = (entry: any) => {
      const rollingEarnings = entry.rollingEarnings || [];
      return rollingEarnings.reduce((sum: number, earning: RollingEarning) => {
        if (!earning.staked) {
          return sum + earning.amount;
        }
        return sum;
      }, 0);
    };

    // Helper function to format leaderboard entries
    const formatEntries = (
      entries: any[],
      valueFunc: (entry: any) => number
    ) => {
      return entries
        .map((entry) => ({
          address: entry.address,
          value: valueFunc(entry),
          displayValue: valueFunc(entry).toLocaleString(),
        }))
        .sort((a, b) => b.value - a.value)
        .slice(0, 10);
    };

    return {
      totalStakedDice: formatEntries(entries, (entry) => entry.staking?.dice || 0),

      totalStakedDton: formatEntries(entries, (entry) => entry.staking?.dton || 0),

      totalDice: formatEntries(entries, (entry) => (entry.diceTokens || 0) + (entry.referralDiceTokens || 0)),

      totalTon: formatEntries(entries, (entry) => entry.tonWinnings || 0),

      premiumRolls: formatEntries(entries, (entry) => entry.gameStats?.premiumRolls || 0),

      regularRolls: formatEntries(entries, (entry) => entry.gameStats?.regularRolls || 0),

      doubleSixes: formatEntries(entries, (entry) => entry.gameStats?.doubleSixes || 0),

      rollingEarnings: formatEntries(entries, (entry) => calculateRollingEarnings(entry)),

      networkSize: formatEntries(entries, (entry) => entry.networkStats?.totalNetworkSize || 0),

      tierLevel: formatEntries(entries, (entry) => entry.currentTier || 0),
    };
  } catch (error: any) {
    console.error('Error fetching leaderboards:', error);
    throw new Error('Failed to fetch leaderboards');
  }
};

// Stake Tokens
export const stakeTokens = async (
  walletAddress: string,
  dtonAmount: number,
  diceAmount: number
): Promise<void> => {
  try {
    const userRef = doc(db, 'userWinnings', walletAddress);
    const userDoc = await getDoc(userRef);

    if (!userDoc.exists()) {
      throw new Error('User not found.');
    }

    const userData = userDoc.data() as UserWinnings;
    const currentTier = userData.currentTier;
    const yieldRate = getYieldRate(currentTier);

    // Initialize staking if not present
    if (!userData.staking) {
      userData.staking = {
        dton: 0,
        dice: 0,
        yieldRate: yieldRate,
        lastStakeTime: Date.now(),
      };
    }

    // Update staking amounts
    const newStakedDton = userData.staking.dton + dtonAmount;
    const newStakedDice = userData.staking.dice + diceAmount;

    // Update yield rate in case tier has changed
    const updatedTier = userData.currentTier;
    const updatedYieldRate = getYieldRate(updatedTier);

    await updateDoc(userRef, {
      'staking.dton': newStakedDton,
      'staking.dice': newStakedDice,
      'staking.yieldRate': updatedYieldRate,
      'staking.lastStakeTime': Date.now(),
      lastUpdated: Date.now(),
    });
  } catch (error: any) {
    console.error('Error staking tokens:', error);
    throw new Error(error.message || 'Failed to stake tokens.');
  }
};

// Get Staked Tokens
export const getStakedTokens = async (walletAddress: string): Promise<StakedTokens | null> => {
  try {
    const userRef = doc(db, 'userWinnings', walletAddress);
    const userDoc = await getDoc(userRef);

    if (userDoc.exists()) {
      const userData = userDoc.data() as UserWinnings;
      return userData.staking || null;
    } else {
      throw new Error('User not found.');
    }
  } catch (error: any) {
    console.error('Error fetching staked tokens:', error);
    throw new Error(error.message || 'Failed to fetch staked tokens.');
  }
};

export const stakeRollingEarnings = async (
  userAddress: string,
  stakeData: {
    dice: number;
    dton: number;
    timestamp: number;
    accruedInterest?: { dice: number; dton: number };
  }
) => {
  const userRef = doc(db, 'userWinnings', userAddress);

  try {
    await runTransaction(db, async (transaction: Transaction) => {
      const userDoc = await transaction.get(userRef);
      if (!userDoc.exists()) {
        throw new Error('User document not found');
      }

      const userData = userDoc.data() as UserWinnings;
      const currentStaking = userData.staking || { dice: 0, dton: 0 };
      const rollingEarnings = userData.rollingEarnings || [];

      // Calculate total available unstaked earnings (WITHOUT interest)
      const unstakedDice = rollingEarnings
        .filter((e: RollingEarning) => e.tokenType === 'DICE' && !e.staked)
        .reduce((sum: number, e: RollingEarning) => sum + (e.amount || 0), 0);

      const unstakedDton = rollingEarnings
        .filter((e: RollingEarning) => e.tokenType === 'DTON' && !e.staked)
        .reduce((sum: number, e: RollingEarning) => sum + (e.amount || 0), 0);

      // Validate base stake amounts (WITHOUT interest)
      if (stakeData.dice > unstakedDice || stakeData.dton > unstakedDton) {
        throw new Error(
          `Insufficient unstaked balance. Available: ${unstakedDice} DICE, ${unstakedDton} DTON. ` +
            `Trying to stake: ${stakeData.dice} DICE, ${stakeData.dton} DTON`
        );
      }

      // Calculate new total staking amounts (including accrued interest)
      const newStaking = {
        dice: currentStaking.dice + stakeData.dice + (stakeData.accruedInterest?.dice || 0),
        dton: currentStaking.dton + stakeData.dton + (stakeData.accruedInterest?.dton || 0),
        lastStakeTime: stakeData.timestamp,
        yieldRate: TIER_YIELDS[userData.currentTier || 0],
      };

      // Update the document
      transaction.update(userRef, {
        staking: newStaking,
        rollingEarnings: [], // Reset rolling earnings as they are now staked
        totalStakedDice: increment(stakeData.dice),
        totalStakedDton: increment(stakeData.dton),
        lastUpdated: stakeData.timestamp,
      });
    });
  } catch (error) {
    console.error('Staking transaction failed:', error);
    throw error;
  }
};

// Calculate and Update Yields (To be run periodically, e.g., via Cloud Functions)
export const calculateAndUpdateYields = async (): Promise<void> => {
  try {
    const winningsCollection = collection(db, 'userWinnings');
    const snapshot = await getDocs(winningsCollection);

    const batch: WriteBatch = writeBatch(db);

    snapshot.forEach((docSnap) => {
      const userData = docSnap.data() as UserWinnings;
      if (userData.staking) {
        const { dice, yieldRate } = userData.staking;
        const yieldAmount = (dice * yieldRate) / 100;

        // Update dice tokens with yield
        const userRef = doc(db, 'userWinnings', docSnap.id);
        batch.update(userRef, {
          diceTokens: increment(yieldAmount),
          lastUpdated: Date.now(),
        });
      }
    });

    await batch.commit();
  } catch (error: any) {
    console.error('Error calculating and updating yields:', error);
    throw new Error(error.message || 'Failed to calculate and update yields.');
  }
};

// Update User Winnings
export const updateUserWinnings = async (
  walletAddress: string,
  newRollResult: RollResult
): Promise<void> => {
  try {
    const userRef = doc(db, 'userWinnings', walletAddress);
    
    await runTransaction(db, async (transaction: Transaction) => {
      const userDoc = await transaction.get(userRef);
      if (!userDoc.exists()) {
        throw new Error('User not found.');
      }

      const userData = userDoc.data() as UserWinnings;
      const rollId = crypto.randomUUID();

      const totalFreeRolls = Math.floor((userData.networkStats?.directReferrals || 0) / 5) + 1; // 1 initial free roll
      const usedFreeRolls = userData.usedTierRolls?.length || 0;
      const freeRollsAvailable = totalFreeRolls - usedFreeRolls;
      const isFreeRoll = newRollResult.type === 'premium' && freeRollsAvailable > 0;

      const updates: any = {
        lastUpdated: Date.now(),
        // Set timestamps
        ...(newRollResult.type === 'regular' 
          ? { lastRegularRollTimestamp: Date.now() }
          : { lastPremiumRollTimestamp: Date.now() }
        ),
        // Add winnings
        tonWinnings: increment(newRollResult.tonAmount),
        diceTokens: increment(newRollResult.diceAmount),
        // Update stats
        'gameStats.totalRolls': increment(1),
        [`gameStats.${newRollResult.type}Rolls`]: increment(1)
      };

      // Add rolling earnings
      const expirationTime = Date.now() + (7 * 24 * 60 * 60 * 1000);
      const newRollingEarnings: RollingEarning[] = [];
      
      if (newRollResult.tonAmount > 0) {
        newRollingEarnings.push({
          amount: newRollResult.tonAmount,
          tokenType: 'DTON',
          timestamp: Date.now(),
          expiresAt: expirationTime,
          staked: false,
          rollId
        });
      }
      
      if (newRollResult.diceAmount > 0) {
        newRollingEarnings.push({
          amount: newRollResult.diceAmount,
          tokenType: 'DICE',
          timestamp: Date.now(),
          expiresAt: expirationTime,
          staked: false,
          rollId
        });
      }

      if (newRollingEarnings.length > 0) {
        updates.rollingEarnings = arrayUnion(...newRollingEarnings);
      }

      // Handle special combinations
      const { dice1, dice2 } = newRollResult.diceValues;
      const sum = dice1 + dice2;
      
      if (dice1 === dice2 && dice1 === 6) {
        updates['gameStats.doubleSixes'] = increment(1);
      }
      
      if (sum === 7 || sum === 11) {
        updates['gameStats.luckyRolls'] = increment(1);
      }

      // Track highest win
      const totalWinValue = newRollResult.tonAmount * 100 + newRollResult.diceAmount;
      if (totalWinValue > (userData.gameStats?.highestWin || 0)) {
        updates['gameStats.highestWin'] = totalWinValue;
        updates['gameStats.bestRollTimestamp'] = Date.now();
      }

      // Add roll to history
      updates.claimHistory = arrayUnion({
        ...newRollResult,
        timestamp: Date.now()
      });

      // Only update usedTierRolls if it's a free premium roll
      if (newRollResult.type === 'premium' && isFreeRoll) {
        updates.usedTierRolls = arrayUnion(Date.now());
      }

      // Apply updates
      transaction.update(userRef, updates);
    });

    // Update referral chain earnings after transaction
    await updateReferralChainEarnings(walletAddress, newRollResult);

  } catch (error: any) {
    console.error('Error updating user winnings:', error);
    throw new Error(error.message || 'Failed to update user winnings.');
  }
};

// Get User Referral Code
export const getUserReferralCode = async (walletAddress: string): Promise<string> => {
  try {
    const userWinnings = await getUserWinnings(walletAddress);
    if (userWinnings && userWinnings.referralCode) {
      return userWinnings.referralCode;
    } else {
      await createUserWinnings(walletAddress);
      return await getUserReferralCode(walletAddress);
    }
  } catch (error: any) {
    console.error('Error fetching referral code:', error);
    throw new Error('Failed to fetch referral code.');
  }
};

// Add Referral Code
export const addReferralCode = async (
  walletAddress: string,
  referralCode: string
): Promise<void> => {
  try {
    const winningsCollection = collection(db, 'userWinnings');
    const referralQuery = query(winningsCollection, where('referralCode', '==', referralCode));
    const querySnapshot = await getDocs(referralQuery);

    if (!querySnapshot.empty) {
      const referringUserDoc = querySnapshot.docs[0];
      const referringUserId = referringUserDoc.id;

      if (referringUserId === walletAddress) {
        throw new Error('You cannot use your own referral code.');
      }

      const userRef = doc(db, 'userWinnings', walletAddress);
      const userDoc = await getDoc(userRef);

      if (!userDoc.exists()) {
        await createUserWinnings(walletAddress);
      }

      const userData = (await getDoc(userRef)).data() as UserWinnings;

      if (userData.referredBy) {
        throw new Error('Referral code already applied.');
      }

      // Update the user's referral data
      await updateDoc(userRef, {
        referredBy: referralCode,
        lastUpdated: Date.now(),
      });

      // Update referrer's stats
      const referrerRef = doc(db, 'userWinnings', referringUserId);
      const referrerData = referringUserDoc.data() as UserWinnings;

      const newDirectReferrals = (referrerData.networkStats?.directReferrals || 0) + 1;
      const newTier = calculateTier(newDirectReferrals);
      const previousTier = referrerData.currentTier;
      const additionalRolls = TIER_CONFIGS[newTier].rollReward - TIER_CONFIGS[previousTier].rollReward;

      await updateDoc(referrerRef, {
        [`referrals.${walletAddress}`]: {
          referredAt: Date.now(),
          totalRolls: 0,
          lastRollAt: null,
          earnedTokens: 0,
        },
        'networkStats.directReferrals': increment(1),
        'networkStats.totalNetworkSize': increment(1),
        currentTier: newTier,
        rollsAvailable: increment(additionalRolls),
        lastUpdated: Date.now(),
      });

      // Initialize referral history tracking
      const referralHistoryRef = doc(db, `referralHistory/${referringUserId}/levels/level1`);
      await setDoc(referralHistoryRef, {
        addresses: arrayUnion(walletAddress)
      }, { merge: true });
    } else {
      throw new Error('Invalid referral code.');
    }
  } catch (error: any) {
    console.error('Error applying referral code:', error);
    throw new Error(error.message || 'Failed to apply referral code.');
  }
};


export const fixNegativeRolls = async (walletAddress: string): Promise<void> => {
  try {
    const userRef = doc(db, 'userWinnings', walletAddress);
    
    await runTransaction(db, async (transaction: Transaction) => {
      const userDoc = await transaction.get(userRef);
      if (!userDoc.exists()) {
        throw new Error('User not found.');
      }

      const userData = userDoc.data() as UserWinnings;
      
      // Calculate correct rolls available
      const freeRollsFromReferrals = Math.floor((userData.networkStats?.directReferrals || 0) / 5);
      const initialFreeRoll = 1;
      const totalFreeRolls = initialFreeRoll + freeRollsFromReferrals;
      const usedFreeRolls = userData.usedTierRolls?.length || 0;
      const correctRollsAvailable = Math.max(totalFreeRolls - usedFreeRolls, 0);

      // Update only if current value is negative or incorrect
      if (userData.rollsAvailable < 0 || userData.rollsAvailable !== correctRollsAvailable) {
        transaction.update(userRef, {
          rollsAvailable: correctRollsAvailable,
          lastUpdated: Date.now()
        });
      }
    });
  } catch (error) {
    console.error('Error fixing negative rolls:', error);
    throw error;
  }
};

export const debugLeaderboardQueries = async () => {
  const winningsCollection = collection(db, 'userWinnings');
  
  try {
    // Test staking query
    console.log('Testing staking leaderboard query...');
    const stakingQuery = query(
      winningsCollection,
      orderBy('staking.dice', 'desc'),
      limit(10)
    );
    await getDocs(stakingQuery);
    console.log('Staking query successful');
  } catch (error: any) {
    console.log('Staking index needed:', error.message);
  }

  try {
    // Test rolls query
    console.log('Testing total rolls query...');
    const rollsQuery = query(
      winningsCollection,
      orderBy('gameStats.totalRolls', 'desc'),
      limit(10)
    );
    await getDocs(rollsQuery);
    console.log('Rolls query successful');
  } catch (error: any) {
    console.log('Rolls index needed:', error.message);
  }

  try {
    // Test network size query
    console.log('Testing network size query...');
    const networkQuery = query(
      winningsCollection,
      orderBy('networkStats.totalNetworkSize', 'desc'),
      limit(10)
    );
    await getDocs(networkQuery);
    console.log('Network query successful');
  } catch (error: any) {
    console.log('Network index needed:', error.message);
  }
};