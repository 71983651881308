// src/components/DiceGame/components/PrizeTable.tsx

import React from 'react';
import { GAME_CONFIG } from '../config';

export default function PrizeTable() {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 max-w-xl mx-auto mt-8">
      {/* Regular Roll Prizes */}
      <div className="bg-black/20 rounded-xl p-4 backdrop-blur-sm">
        <h3 className="text-lg font-bold text-center mb-4 text-emerald-400">
          Regular Roll Prizes
        </h3>
        <div className="space-y-2 text-sm">
          <div className="flex justify-between items-center">
            <span className="flex items-center gap-2">
              <span>🎯</span>
              <span>Double 6</span>
            </span>
            <span>{GAME_CONFIG.PRIZES.regular.doubleSix.dice} DICE</span>
          </div>
          <div className="flex justify-between items-center">
            <span className="flex items-center gap-2">
              <span>🎲</span>
              <span>Any Double</span>
            </span>
            <span>{GAME_CONFIG.PRIZES.regular.double.dice} DICE</span>
          </div>
          <div className="flex justify-between items-center">
            <span className="flex items-center gap-2">
              <span>🍀</span>
              <span>Lucky (7,11)</span>
            </span>
            <span>{GAME_CONFIG.PRIZES.regular.lucky.dice} DICE</span>
          </div>
          <div className="flex justify-between items-center text-sm opacity-75">
            <span>Other Rolls</span>
            <span>Sum × {GAME_CONFIG.REGULAR_MULTIPLIER} DICE</span>
          </div>
        </div>
      </div>

      {/* Premium Roll Prizes */}
      <div className="bg-black/20 rounded-xl p-4 backdrop-blur-sm">
        <h3 className="text-lg font-bold text-center mb-4 text-purple-400">
          Premium Roll Prizes
        </h3>
        <div className="space-y-2 text-sm">
          <div className="flex justify-between items-center">
            <span className="flex items-center gap-2">
              <span>🎯</span>
              <span>Double 6</span>
            </span>
            <span>
              {GAME_CONFIG.PRIZES.premium.doubleSix.dton} dTON + 
              {GAME_CONFIG.PRIZES.premium.doubleSix.dice} DICE
            </span>
          </div>
          <div className="flex justify-between items-center">
            <span className="flex items-center gap-2">
              <span>🎲</span>
              <span>Any Double</span>
            </span>
            <span>
              {GAME_CONFIG.PRIZES.premium.double.dton} dTON + 
              {GAME_CONFIG.PRIZES.premium.double.dice} DICE
            </span>
          </div>
          <div className="flex justify-between items-center">
            <span className="flex items-center gap-2">
              <span>🍀</span>
              <span>Lucky (7,11)</span>
            </span>
            <span>
              {GAME_CONFIG.PRIZES.premium.lucky.dton} dTON + 
              {GAME_CONFIG.PRIZES.premium.lucky.dice} DICE
            </span>
          </div>
          <div className="flex justify-between items-center text-sm opacity-75">
            <span>Other Rolls</span>
            <span>{GAME_CONFIG.PRIZES.premium.base.dton} dTON + Sum × {GAME_CONFIG.PREMIUM_MULTIPLIER} DICE</span>
          </div>
        </div>
      </div>
    </div>
  );
}