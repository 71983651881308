import { db } from '../firebase';
import { doc, setDoc, collection, getDocs, runTransaction } from 'firebase/firestore';
import { STAKING_CONFIG } from '../config/staking.config';

interface GlobalStakingStats {
  remainingRewards: number;    // From 200M allocation
  totalStakedDice: number;     // For APY calculations
  totalStakers: number;
}

// Calculate both total staked and remaining rewards
export const calculateGlobalStats = async (): Promise<{
  remainingRewards: number;
  totalStakedDice: number;
  totalUsers: number;
}> => {
  const userWinningsRef = collection(db, 'userWinnings');
  const querySnapshot = await getDocs(userWinningsRef);
  
  let totalDistributed = 0;
  let totalStaked = 0;
  let totalUsers = 0;
  
  querySnapshot.forEach((doc) => {
    const userData = doc.data();
    
    if (userData.diceTokens) totalDistributed += userData.diceTokens;
    if (userData.referralDiceTokens) totalDistributed += userData.referralDiceTokens;
    
    if (userData.staking?.dice) {
      totalStaked += userData.staking.dice;
      totalUsers++;
    }
  });
  
  return {
    remainingRewards: STAKING_CONFIG.TOTAL_STAKING_ALLOCATION - totalDistributed,
    totalStakedDice: totalStaked,
    totalUsers
  };
};

export const getGlobalStakingStats = async (): Promise<GlobalStakingStats> => {
  try {
    const stats = await calculateGlobalStats();
    const statsRef = doc(collection(db, 'staking_stats'), 'global');
    
    const newStats: GlobalStakingStats = {
      remainingRewards: stats.remainingRewards,
      totalStakedDice: stats.totalStakedDice,
      totalStakers: stats.totalUsers
    };
    
    // Always update with latest calculated values
    await setDoc(statsRef, newStats);
    
    return newStats;
  } catch (error) {
    console.error('Error getting global staking stats:', error);
    return {
      remainingRewards: STAKING_CONFIG.TOTAL_STAKING_ALLOCATION,
      totalStakedDice: 0,
      totalStakers: 0
    };
  }
};

export const updateGlobalStakingStats = async (
  amount: number,
  isNewStaker: boolean
): Promise<void> => {
  const statsRef = doc(collection(db, 'staking_stats'), 'global');
  
  await runTransaction(db, async (transaction) => {
    // Always get fresh stats when updating
    const stats = await calculateGlobalStats();
    
    transaction.set(statsRef, {
      remainingRewards: stats.remainingRewards,
      totalStakedDice: stats.totalStakedDice,
      totalStakers: stats.totalUsers
    });
  });
};

export const calculateDynamicAPY = (
  userStakingPosition: number,
  globalStats: GlobalStakingStats,
  userTier: number
): {
  diceAPY: number;
  dtonAPY: number;
  earlyStakerBonus: number;
  tierMultiplier: number;
  effectiveAPY: number;
} => {
  const tierNames = ['BRONZE', 'SILVER', 'GOLD', 'PLATINUM', 'DIAMOND'] as const;
  const currentTier = tierNames[userTier];
  
  // Calculate base DICE APY based on total staked amount
  let baseAPY = STAKING_CONFIG.DICE_APY.MIN_APY;
  
  for (const threshold of STAKING_CONFIG.DICE_APY.REDUCTION_THRESHOLDS) {
    if (globalStats.totalStakedDice <= threshold.amount) {
      baseAPY = threshold.apy;
      break;
    }
  }

  // Calculate early staker bonus
  let earlyStakerBonus = 1.0;
  if (userStakingPosition <= 100) {
    earlyStakerBonus = STAKING_CONFIG.EARLY_STAKER_BONUS.FIRST_100_USERS;
  } else if (userStakingPosition <= 500) {
    earlyStakerBonus = STAKING_CONFIG.EARLY_STAKER_BONUS.FIRST_500_USERS;
  } else if (userStakingPosition <= 1000) {
    earlyStakerBonus = STAKING_CONFIG.EARLY_STAKER_BONUS.FIRST_1000_USERS;
  }

  // Get tier multiplier and dTON APY based on tier
  const tierMultiplier = STAKING_CONFIG.TIER_MULTIPLIERS[currentTier];
  const dtonAPY = STAKING_CONFIG.DTON_APY[currentTier];

  // Calculate final effective APY with all multipliers
  const effectiveAPY = baseAPY * earlyStakerBonus * tierMultiplier;

  return {
    diceAPY: baseAPY,
    dtonAPY,
    earlyStakerBonus,
    tierMultiplier,
    effectiveAPY
  };
};